import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import "./reset.css";
import "./App.css";

import TypingInterface from "./Components/TypingInterface";
import { setColors, colors } from "./utils/color";
import TypingDataAndControls from "./Components/TypingDataAndControls";
import { supabase } from "./utils/supabaseClient";
import { Container, Spinner, Text, useColorMode } from "@chakra-ui/react";
import Stats from "./Components/Stats";
import {
  calculateAVG_IKI,
  calculateErrorCorrectionsPerChar,
  calculateErrorRate,
  calculateKeystrokesPerChar,
  calculateRolloverRatio,
  calculateWPMFromPromptData,
} from "./utils/calculations";
import { Session } from "@supabase/supabase-js";

export type PromptData = {
  index: number;
  prompt: Quote;
  startTime: Date;
  // sentence: string;
  keyPresses: KeyPressRow[];
  wpm: number | null;
  avgIki: number | null;
  errorRate: number | null;
  ecpc: number | null;
  kspc: number | null;
  ror: number | null;
  seconds: number;
  userInput: string[];
  userCorrectIndex: number;
  id: number;
  ticker: number;
  expectedWPM?: number;
};

const TICKER_INTERVAL = 100;

export type UISettings = {
  showStats: boolean;
  loading: boolean;
};

export type KeyPressRow = {
  letter: string;
  keyCode: number;
  pressTime: number;
  releaseTime: number | null;
};

export type History = PromptData[];

interface ITypingMain {
  history: History;
  setHistory: React.Dispatch<React.SetStateAction<History>>;
  session: Session | null;
}

export type Quote = {
  quote: string;
  author: string;
  author_link: string;
  source: string;
  author_image: string;
  type: string;
  id: number;
};

const isPrintableCharacter = (key: string) => {
  const isShift = key === "Shift";
  const isMeta = key === "Meta";
  const isAlt = key === "Alt";
  const isControl = key === "Control";
  const isEnter = key === "Enter";
  const isEscape = key === "Escape";
  const isTab = key === "Tab";
  const isFunctionKey = key.startsWith("F") && key.length >= 2;
  const isArrowKey = key.startsWith("Arrow");
  const isCapsLock = key === "CapsLock";

  if (
    isShift ||
    isMeta ||
    isAlt ||
    isControl ||
    isEnter ||
    isEscape ||
    isTab ||
    isFunctionKey ||
    isArrowKey ||
    isCapsLock
  ) {
    return false;
  }

  // This regular expression matches any printable character, including symbols and punctuation
  const regex = /^[\u0020-\u007e\u00a0-\u00ff]+$/;
  return regex.test(key);
};

const TypingMain = ({ history, setHistory, session }: ITypingMain) => {
  const [promptData, setPromptData] = useState<PromptData>({
    index: 0,
    prompt: {
      quote: "",
      author: "",
      author_link: "",
      source: "",
      author_image: "",
      type: "",
      id: 0,
    },
    startTime: new Date(),
    keyPresses: [],
    wpm: null,
    ecpc: null,
    kspc: null,
    ror: null,
    seconds: 0,
    userInput: [],
    userCorrectIndex: 0,
    errorRate: null,
    avgIki: null,
    id: 0,
    ticker: 0,
  });
  const [seconds, setSeconds] = useState<number>(0);
  const [uiSettings, setUiSettings] = useState<UISettings>({
    showStats: false,
    loading: false,
  });
  const { colorMode } = useColorMode();
  const completed = useRef(false);

  async function getRandomQuote(): Promise<Quote> {
    const { data, error } = await supabase
      .from("random_quote")
      .select("quote, author, quote_type, link, image, source, id")
      .limit(1);

    if (
      error ||
      !data ||
      data.length === 0 ||
      data[0].quote === null ||
      data[0].author === null ||
      data[0].quote_type === null ||
      data[0].link === null ||
      data[0].image === null ||
      data[0].source === null ||
      data[0].id === null
    ) {
      console.log(error);
      return {
        quote: "Error loading quote",
        author: "Jim",
        author_image: "",
        author_link: "",
        source: "",
        type: "error",
        id: 0,
      };
    }
    return {
      quote: data[0].quote,
      author: data[0].author,
      type: data[0].quote_type,
      author_link: data[0].link,
      author_image: data[0].image,
      source: data[0].source,
      id: data[0].id,
    };
  }

  if (colorMode === "dark") {
    setColors(colors.dark);
  } else {
    setColors(colors.light);
  }

  function stringToSeed(s) {
    let seed = 0;
    for (let i = 0; i < s.length; i++) {
      seed = (seed << 5) - seed + s.charCodeAt(i);
      seed |= 0; // Convert to a 32-bit integer
    }
    return seed;
  }
  function random(seed) {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  const caluclateExpectedWPM = () => {
    let promptString = promptData.prompt.quote;
    let seed = stringToSeed(promptString);

    let totalWPM = 0;
    history.forEach((prompt) => {
      totalWPM += prompt.wpm || 0;
    });

    const avgWPM = totalWPM / history.length;
    const plusOrMinus5percent = avgWPM * 0.05;
    const min = avgWPM - plusOrMinus5percent;
    const max = avgWPM + plusOrMinus5percent;

    const expectedWPM = Math.floor(random(seed) * (max - min + 1) + min);

    return expectedWPM;
  };

  async function saveTypingData(promptData: PromptData) {
    // Insert typing history data
    const { data: typingHistoryData, error: typingHistoryError } =
      await supabase
        .from("typing_history")
        .insert([
          {
            user_id: session?.user?.id as string,
            quote_id: promptData.prompt.id,
            wpm: promptData.wpm || 0,
            avg_iki: promptData.avgIki || 0,
            error_rate: promptData.errorRate || 0,
            ecpc: promptData.ecpc || 0,
            kspc: promptData.kspc || 0,
            ror: promptData.ror || 0,
            seconds: promptData.seconds,
            user_input: promptData.userInput,
          },
        ])
        .select();

    if (typingHistoryError) {
      console.error("Error inserting typing history data:", typingHistoryError);
      return;
    }

    // Insert keystrokes data
    const keystrokesData = promptData.keyPresses.map((keyPress, index) => ({
      typing_history_id: typingHistoryData[0].id,
      letter: keyPress.letter,
      keycode: keyPress.keyCode,
      press_time: new Date(keyPress.pressTime).toISOString(),
      release_time: keyPress.releaseTime
        ? new Date(keyPress.releaseTime).toISOString()
        : null,
      stroke_order: index + 1,
      user_id: session?.user?.id as string,
    }));

    const { error: keystrokesError } = await supabase
      .from("keystrokes")
      .insert(keystrokesData);

    if (keystrokesError) {
      console.error("Error inserting keystrokes data:", keystrokesError);
    }

    return typingHistoryData[0].id;
  }

  async function completePrompt() {
    if (promptData.keyPresses.length === 0) return;
    const wpm = calculateWPMFromPromptData(promptData, false);
    const errorRate = calculateErrorRate(promptData);
    const avgIki = calculateAVG_IKI(promptData);
    const ecpc = calculateErrorCorrectionsPerChar(promptData);
    const kspc = calculateKeystrokesPerChar(promptData);
    const ror = calculateRolloverRatio(promptData);
    console.log("ror", ror);
    console.log("kspc", kspc);
    console.log("ecpc", ecpc);
    console.log("avgIki", avgIki);
    console.log("errorRate", errorRate);
    console.log("wpm", wpm);

    const newPromptData = {
      ...promptData,
      wpm,
      errorRate,
      avgIki,
      ecpc,
      kspc,
      ror,
    };

    const id = await saveTypingData(newPromptData);
    newPromptData.id = id as number;
    setHistory([...history, newPromptData]);
    setUiSettings({
      showStats: true,
      loading: false,
    });
  }

  const focusInput = (e?) => {
    if (e.target.tagName.toLowerCase() === "a") {
      return;
    }
    if (e) e?.preventDefault();
    const input = document.querySelector(
      ".hiddenTextInput"
    ) as HTMLElement | null;
    if (input && document.activeElement != input) {
      input.focus();
    }
  };

  const allReleased = (): boolean => {
    if (promptData.keyPresses.length === 0) return false;

    const allReleased = promptData.keyPresses.every(
      (keyPress) => keyPress.releaseTime !== null
    );

    return allReleased;
  };

  useEffect(() => {
    if (
      !completed.current &&
      promptData.index >= promptData.prompt.quote.length &&
      allReleased()
    ) {
      completed.current = true;

      setUiSettings({
        showStats: false,
        loading: true,
      });

      const handleCompletion = async () => {
        await completePrompt();
      };

      handleCompletion();
    }
  }, [promptData]);

  const keyHandler = (
    e: React.KeyboardEvent<HTMLInputElement>,
    promptData: PromptData
  ) => {
    if (!promptData.prompt || !promptData.prompt.quote) return;

    if (!isInputFocused()) return;

    const time = new Date().getTime();
    const isBackspace = e.key === "Backspace";

    // Update keyPresses with the current key press
    const keyPress: KeyPressRow = {
      letter: e.key,
      keyCode: e.keyCode,
      pressTime: time,
      releaseTime: null,
    };

    // Update keyPresses with the current key press
    setPromptData({
      ...promptData,
      keyPresses: promptData.keyPresses.concat([keyPress]),
    });

    // Check if the pressed key is a printable character
    if (!isPrintableCharacter(e.key) && !isBackspace) {
      return;
    }

    if (!isPrintableCharacter(e.key)) return;

    if (isBackspace) {
      if (promptData.index > 0) {
        setPromptData({
          ...promptData,
          index: promptData.index - 1,
          keyPresses: promptData.keyPresses.concat([keyPress]),
          userInput: promptData.userInput.slice(0, -1),
        });
      }
      return;
    } else if (e.key === promptData.prompt.quote[promptData.index]) {
      // if key is last key in prompt and correct, complete prompt
      if (promptData.index >= promptData.prompt.quote.length - 1) {
        //TODO: add final keypress
        setPromptData({
          ...promptData,
          index: promptData.index + 1,
          keyPresses: promptData.keyPresses.concat([keyPress]),
          userInput: isPrintableCharacter(e.key)
            ? promptData.userInput.concat([e.key])
            : promptData.userInput,
          userCorrectIndex: promptData.userCorrectIndex + 1,
        });
        return;
      }

      // if key is first key in prompt and correct, start timer
      if (promptData.index === 0) {
        setPromptData({
          ...promptData,
          index: promptData.index + 1,
          startTime: new Date(),
          keyPresses: promptData.keyPresses.concat([keyPress]),
          userInput: isPrintableCharacter(e.key)
            ? promptData.userInput.concat([e.key])
            : promptData.userInput,
          userCorrectIndex: promptData.userCorrectIndex + 1,
        });
      } else {
        // if key is not first key in prompt
        setPromptData({
          ...promptData,
          index: promptData.index + 1,
          keyPresses: promptData.keyPresses.concat([keyPress]),
          userInput: isPrintableCharacter(e.key)
            ? promptData.userInput.concat([e.key])
            : promptData.userInput,
          userCorrectIndex: promptData.userCorrectIndex + 1,
        });
      }
    } // if key is incorrect
    else {
      setPromptData({
        ...promptData,
        keyPresses: promptData.keyPresses.concat([keyPress]),
        userInput: isPrintableCharacter(e.key)
          ? promptData.userInput.concat([e.key])
          : promptData.userInput,
        index: promptData.index + 1,
      });
    }
  };
  useEffect(() => {
    const interval = setInterval(
      () => setSeconds(getSeconds(promptData.startTime.getTime())),
      1000
    );
    return () => clearInterval(interval);
  }, [seconds, promptData.startTime]);

  useEffect(() => {
    const fetchQuote = async () => {
      const quote = await getRandomQuote();
      setPromptData({
        ...promptData,
        prompt: quote,
      });
    };

    fetchQuote();
  }, []);

  const getSeconds = (startTime: number): number => {
    if (promptData.index === 0) {
      return 0;
    }
    const time = new Date().getTime() - startTime;
    return Math.floor(time / 1000);
  };

  const reset = async () => {
    completed.current = false;
    setPromptData({
      index: 0,
      prompt: await getRandomQuote(),
      startTime: new Date(),
      keyPresses: [],
      wpm: null,
      ror: null,
      ecpc: null,
      kspc: null,
      seconds: 0,
      userInput: [],
      userCorrectIndex: 0,
      errorRate: null,
      avgIki: null,
      id: 0,
      ticker: 0,
      expectedWPM: caluclateExpectedWPM(),
    });
    setSeconds(0);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key.toLowerCase() === "r") {
        event.preventDefault();
        reset();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setPromptData({
      ...promptData,
      expectedWPM: caluclateExpectedWPM(),
    });
  }, [promptData.prompt, history]);

  //update ticker every tickerInterval
  useEffect(() => {
    const interval = setInterval(() => {
      setPromptData({
        ...promptData,
        ticker: promptData.ticker + 1,
      });
    }, 500);
    return () => clearInterval(interval);
  }, [promptData]);

  return (
    <div>
      <Center>
        {!uiSettings.showStats && !uiSettings.loading && (
          <>
            <TypingDataAndControls
              seconds={seconds}
              reset={reset}
              promptData={promptData}
              expectedWPM={promptData.expectedWPM || 0}
            />
            <TypingInterface
              keyHandler={keyHandler}
              promptData={promptData}
              focusInput={focusInput}
              setPromptData={setPromptData}
            />
          </>
        )}
        {!uiSettings.showStats && uiSettings.loading && (
          <>
            <TypingDataAndControls
              seconds={seconds}
              reset={reset}
              promptData={promptData}
              expectedWPM={promptData.expectedWPM || 0}
            />
            <Container
              mt={10}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={8}
            >
              <Spinner />
              <Text>Uploading keystrokes...</Text>
            </Container>
          </>
        )}
        {uiSettings.showStats && (
          <Stats
            setUiSettings={setUiSettings}
            promptData={history[history.length - 1]}
            expectedWPM={promptData.expectedWPM || 0}
            history={history}
            setSeconds={setSeconds}
            getRandomQuote={getRandomQuote}
            setPromptData={setPromptData}
            completed={completed}
          />
        )}
      </Center>
    </div>
  );
};

export default TypingMain;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

function isInputFocused() {
  return document.activeElement?.classList.contains("hiddenTextInput") ?? false;
}
