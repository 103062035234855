import React, { useState, useEffect } from "react";
import { Route, RouterProvider, Routes, useParams } from "react-router-dom";

import {
  ChakraProvider,
  extendTheme,
  type ThemeConfig,
} from "@chakra-ui/react";
import { supabase } from "./utils/supabaseClient";
import { Session, User } from "@supabase/supabase-js";
import { createBrowserRouter } from "react-router-dom";
import TypingMain, { KeyPressRow, PromptData } from "./TypingMain";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Profile from "./Components/Profile";
import type { History } from "./TypingMain";
import Header from "./Components/Header";
import HistoryComponent from "./Components/History";
import Stats from "./Components/Stats";
import HistoryIdx from "./Components/HistoryIdx";
import Account from "./Components/Account";
import Leaderboard from "./Components/Leaderboard";
import PublicProfile from "./Components/PublicProfile";

interface Keystroke {
  id: number;
  user_id: string | null;
  typing_history_id: number | null;
  letter: string | null;
  keycode: number | null;
  press_time: string | null;
  release_time: string | null;
  stroke_order: number | null;
}

function keystrokesToKeyPressRows(keystrokes: Keystroke[]): KeyPressRow[] {
  return keystrokes.map((keystroke) => ({
    letter: keystroke.letter as string,
    keyCode: keystroke.keycode as number,
    pressTime: new Date(keystroke.press_time as string).getTime(),
    releaseTime: new Date(keystroke.release_time as string).getTime(),
  }));
}

interface TypingHistoryEntry {
  id: number;
  user_id: string;
  created_at: string;
  quote_id: number;
  wpm: number;
  kspc: number;
  ecpc: number;
  error_rate: number;
  ror: number;
  avg_iki: number;
  seconds: number;
  user_input: string[];
  quote: string;
  author_name: string;
  author_link: string;
  source: string;
  author_image: string;
  quote_type: string;
  keystrokes: Keystroke[];
}

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: false,
};
const theme = extendTheme({ config });

type LayoutProps = {
  children: React.ReactNode;
  avatarUrl: string | null;
};

const Layout = ({ children, avatarUrl }: LayoutProps) => {
  return (
    <>
      <Header avatarUrl={avatarUrl} />
      <main>{children}</main>
    </>
  );
};

const App = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [history, setHistory] = useState<History>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [fullName, setFullName] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [isSubscribedToEmails, setIsSubscribedToEmails] =
    useState<boolean>(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout avatarUrl={avatarUrl}>
          <TypingMain
            history={history}
            setHistory={setHistory}
            session={session}
          />
        </Layout>
      ),
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "profile/*",
      element: (
        <Layout avatarUrl={avatarUrl}>
          <Routes>
            <Route
              path="/"
              element={
                <Profile
                  isAdmin={isAdmin}
                  session={session}
                  history={history}
                  fullName={fullName}
                  setFullName={setFullName}
                  avatarUrl={avatarUrl}
                  user={user}
                  setAvatarUrl={setAvatarUrl}
                  username={username}
                  setUsername={setUsername}
                />
              }
            />
            <Route
              path=":username"
              element={<PublicProfile session={session} />}
            />
          </Routes>
        </Layout>
      ),
    },
    {
      path: "history/*",
      element: (
        <Layout avatarUrl={avatarUrl}>
          <Routes>
            <Route
              path="/"
              element={<HistoryComponent history={history} session={session} />}
            />
            <Route path=":id" element={<HistoryIdx history={history} />} />
          </Routes>
        </Layout>
      ),
    },
    {
      path: "account",
      element: (
        <Layout avatarUrl={avatarUrl}>
          <Account
            session={session}
            isSubcribedToEmails={isSubscribedToEmails}
            setIsSubcribedToEmails={setIsSubscribedToEmails}
            setHistory={setHistory}
          />
        </Layout>
      ),
    },
    {
      path: "leaderboard",
      element: (
        <Layout avatarUrl={avatarUrl}>
          <Leaderboard session={session} />
        </Layout>
      ),
    },
  ]);

  async function getIsAdmin() {
    if (!session) {
      return;
    }

    const { data, error } = await supabase
      .from("roles")
      .select(`is_admin`)
      .eq("user_id", session?.user.id)
      .single();

    if (error) {
      throw error;
    }

    if (data) {
      setIsAdmin(data.is_admin);
    }
  }

  function typingHistoryToPromptData(
    history: TypingHistoriesResponse
  ): PromptData[] {
    const promptDatas: PromptData[] = [];
    if (!history) {
      return promptDatas;
    }
    for (const h of history) {
      promptDatas.push({
        prompt: {
          quote: h.quote as string,
          author: h.author_name as string,
          author_link: h.author_link as string,
          source: h.source as string,
          author_image: h.author_image as string,
          type: h.quote_type as string,
          id: h.quote_id as number,
        },
        wpm: h.wpm,
        startTime: new Date(h.created_at as string),
        kspc: h.kspc,
        ecpc: h.ecpc,
        errorRate: h.error_rate,
        ror: h.ror,
        avgIki: h.avg_iki,
        seconds: h.seconds as number,
        userInput: h.user_input as string[],
        userCorrectIndex: 0,
        index: 0,
        keyPresses: keystrokesToKeyPressRows(h.keystrokes),
        id: h.id,
        ticker: 0,
      });
    }
    return promptDatas;
  }

  type TypingHistoriesResponse = Awaited<ReturnType<typeof getTypingHistories>>;

  async function getTypingHistories() {
    if (!session) {
      return;
    }

    const { data, error } = await supabase.rpc(
      "user_typing_history_with_quotes"
    );

    if (error) {
      throw error;
    }

    const historyData: TypingHistoryEntry[] = data.map((entry: any) => {
      const isValidJson = (str: string) => {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          console.log("Invalid JSON: " + str);
          return false;
        }
      };

      const parsedKeystrokes: Keystroke[] = isValidJson(
        JSON.stringify(entry.keystrokes)
      )
        ? JSON.parse(JSON.stringify(entry.keystrokes))
        : [];

      return {
        ...entry,
        keystrokes: parsedKeystrokes.sort(
          (a: Keystroke, b: Keystroke) =>
            (a.stroke_order || 0) - (b.stroke_order || 0)
        ),
      };
    });

    return historyData;
  }

  const getProfile = async () => {
    try {
      if (!session) {
        return;
      }

      let { data, error, status } = await supabase
        .from("profiles")
        .select(
          `username, website, avatar_url, full_name, is_subscribed_to_emails`
        )
        .eq("id", session?.user.id)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        setAvatarUrl(data.avatar_url);
        setFullName(data.full_name);
        setUsername(data.username);
        setIsSubscribedToEmails(data.is_subscribed_to_emails || false);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      if (session) {
        setUser(session?.user);
      }
    }
  };

  useEffect(() => {
    getIsAdmin();
    getProfile();
  }, [isAdmin, session]);

  useEffect(() => {
    getTypingHistories().then((data) => {
      if (data) {
        setHistory(typingHistoryToPromptData(data));
      }
    });
  }, [session]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session) {
        router.navigate("/");
      } else {
        router.navigate("/login");
      }
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);

      if (session) {
        router.navigate("/");
      } else {
        router.navigate("/login");
      }
    });
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
};

export default App;
