import React, { SetStateAction } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { supabase } from "../utils/supabaseClient";
import { Session } from "@supabase/supabase-js";
import { Switch } from "@chakra-ui/react";
import DeleteAccount from "./DangerZone/DeleteAccount";
import ResetAccount from "./DangerZone/ResetAccount";
import { History } from "../TypingMain";

interface AccountProps {
  session: Session | null;
  isSubcribedToEmails: boolean;
  setIsSubcribedToEmails: React.Dispatch<React.SetStateAction<boolean>>;
  setHistory: React.Dispatch<SetStateAction<History>>;
}

const Account = ({
  session,
  isSubcribedToEmails,
  setIsSubcribedToEmails,
  setHistory,
}: AccountProps) => {
  const handleToggleEmails = async () => {
    try {
      const user = session?.user;
      if (user) {
        const { data, error } = await supabase
          .from("profiles")
          .update({ is_subscribed_to_emails: !isSubcribedToEmails })
          .eq("id", user.id);

        if (error) {
          throw error;
        }
        setIsSubcribedToEmails(!isSubcribedToEmails);
      }
    } catch (error) {
      console.error("Error submitting deletion request:", error.message);
    }
  };

  return (
    <Container>
      <Text fontSize={"2rem"} mb="4">
        Account Settings
      </Text>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0">
          Enable email alerts?
        </FormLabel>
        <Switch
          id="email-alerts"
          isChecked={isSubcribedToEmails}
          onChange={handleToggleEmails}
        />
      </FormControl>
      <Text fontSize={"2rem"} mb="4">
        Danger Zone
      </Text>
      <Flex flexDir={"column"} gap={3}>
        <ResetAccount session={session} setHistory={setHistory} />
        <DeleteAccount session={session} />
      </Flex>
    </Container>
  );
};

export default Account;
