import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  useColorModeValue,
  useDisclosure,
  Text,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Session } from "@supabase/supabase-js";
import React, { Dispatch, SetStateAction } from "react";
import { supabase } from "../../utils/supabaseClient";
import { VscDebugRestart } from "react-icons/vsc";
import { useToast } from "@chakra-ui/react";
import { History } from "../../TypingMain";

interface ResetAccountProps {
  session: Session | null;
  setHistory: Dispatch<SetStateAction<History>>;
}

const ResetAccount = ({ session, setHistory }: ResetAccountProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);
  const toast = useToast();

  async function handleReset() {
    const { data, error } = await supabase.rpc("reset_user_account");

    if (error) {
      console.error("Error resetting user account:", error);
      toast({
        title: "Error resetting account.",
        description: "We couldn't reset your account",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      console.log("User account reset successfully");
      setHistory([]);
      toast({
        title: "Account reset.",
        description: "We've reset your account for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <Container padding={0}>
      <Flex gap={3} alignItems={"center"} justifyContent={"center"}>
        <Text>Reset all you data</Text>
        <Spacer />
        <Button
          background={useColorModeValue("red.500", "red.300")}
          color={useColorModeValue("white", "gray.800")}
          onClick={onOpen}
          gap={2}
        >
          <VscDebugRestart /> <Text>Reset Account</Text>
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset your account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleReset();
                  onClose();
                }}
                ml={3}
              >
                Reset
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default ResetAccount;
