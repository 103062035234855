import {
  Avatar,
  Badge,
  Box,
  Card,
  Center,
  Container,
  Flex,
  Heading,
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { Session } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";
import { Database } from "../utils/database.types";

interface PublicProfileProps {
  session: Session | null;
}

const PublicProfile = ({ session }: PublicProfileProps) => {
  const [profile, setProfile] = React.useState<ProfileResponseType | null>(
    null
  );
  const [typingHistories, setTypingHistories] = useState(null);
  const { username } = useParams();
  const { colorMode } = useColorMode();

  if (!username) return <div>Not Found</div>;

  async function getProfile() {
    const { data, error } = await supabase
      .from("profile_with_roles")
      .select("*")
      .eq("username", username)
      .single();
    if (error) {
      console.log(error);
    }
    if (data) {
      try {
        data.recent_typing_histories = JSON.parse(
          data.recent_typing_histories as any
        );
      } catch (e) {
        console.log(e);
      }
      console.log(data.recent_typing_histories);
      setProfile(data);
      return data;
    }
  }
  type TypingHistoryRow = Database["public"]["Tables"]["typing_history"]["Row"];
  function calculateAVGWPM(typingHistories: TypingHistoryRow[]) {
    if (typingHistories.length === 0) return 0;
    let totalWPM = 0;
    typingHistories.forEach((history) => {
      totalWPM += history.wpm;
    });
    return totalWPM / typingHistories.length;
  }

  type ProfileResponseType = Awaited<ReturnType<typeof getProfile>>;

  useEffect(() => {
    getProfile();
  }, []);

  function getLengthOfRecentTypingData(
    recentTypingHistories: TypingHistoryRow[]
  ): number {
    return recentTypingHistories.length;
  }

  return (
    <Container>
      <Center>
        {!profile && <Spinner />}
        {profile && (
          <Center
            mb={"3rem"}
            display={"flex"}
            flexDir={"column"}
            alignItems={"center"}
            gap="6"
          >
            <Box
              width="20rem"
              boxShadow={"2xl"}
              rounded={"3xl"}
              p={6}
              textAlign={"center"}
              className={colorMode === "dark" ? "colour-dark" : "colour"}
            >
              <Avatar
                size={"xl"}
                src={profile?.avatar_url || undefined}
                mb={4}
                pos={"relative"}
                title="Click to change avatar"
                cursor={"pointer"}
              />
              <Card
                bg={useColorModeValue("white", "gray.900")}
                boxShadow={"2xl"}
                rounded={"3xl"}
                p={6}
                textAlign={"center"}
                alignItems="center"
                alignContent={"center"}
              >
                <Heading fontSize={"2xl"} fontFamily={"body"}>
                  <Flex alignItems={"center"} justifyContent="center">
                    <div>{username} </div>
                  </Flex>
                </Heading>
                <Text fontWeight={600} color={"gray.500"} mb={4}>
                  {profile?.email}
                </Text>

                {profile.is_admin ? (
                  <Badge colorScheme="green">Admin</Badge>
                ) : (
                  <Badge colorScheme="blue">Verified user</Badge>
                )}
              </Card>
            </Box>

            <Container>
              <Card
                bg={useColorModeValue("white", "gray.900")}
                boxShadow={"2xl"}
                rounded={"3xl"}
                p={6}
                textAlign={"center"}
                alignItems="center"
                alignContent={"center"}
                mt={6}
              >
                <Stat>
                  <StatLabel>AVG WPM</StatLabel>
                  <StatNumber>
                    {calculateAVGWPM(profile.recent_typing_histories).toFixed(
                      2
                    )}
                  </StatNumber>
                  <StatHelpText>
                    From the past{" "}
                    {getLengthOfRecentTypingData(
                      profile.recent_typing_histories
                    )}{" "}
                    {getLengthOfRecentTypingData(
                      profile.recent_typing_histories
                    ) === 1
                      ? "prompt"
                      : "prompts"}
                  </StatHelpText>
                </Stat>
              </Card>
            </Container>
          </Center>
        )}
      </Center>
    </Container>
  );
};

export default PublicProfile;
