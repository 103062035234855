import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
  Center,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { Link as RouterLink, redirect, Router } from "react-router-dom";
import { supabase } from "../utils/supabaseClient";

interface ISignUpProps {
  setEmail: (email: string) => void;
  email: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  handleSignup: (e: any) => Promise<void>;
  password: string;
  setPassword: (password: string) => void;
  confirmPassword: string;
  setConfirmPassword: (confirmPassword: string) => void;
  signUpWithGoogle: () => Promise<void>;
}

const SignUpForm = ({
  setEmail,
  email,
  loading,
  setLoading,
  handleSignup,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  signUpWithGoogle,
}: ISignUpProps) => {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <h1 className="title">VORAK</h1>
          <Heading fontSize={"4xl"}>Sign up for an account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to begin typing like a pro ⌨️️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl id="confirm-password">
              <FormLabel>Confirm Password</FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleSignup}
              >
                Sign up
              </Button>
              <Button
                w={"full"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
                onClick={signUpWithGoogle}
              >
                <Center>
                  <Text>Sign up with Google</Text>
                </Center>
              </Button>
              <Link as={RouterLink} color={"blue.400"} to="/login">
                Already have an account?
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

const Signup = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"error" | "success">("error");

  const signUpWithGoogle = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
      });
      if (error) throw error;
    } catch (error) {
      setAlertType("error");
      setAlertMessage(error.error_description || error.message);
      // remove the alert after 5 seconds
      setTimeout(() => {
        setAlertMessage("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setAlertMessage("Passwords do not match");
      setAlertType("error");

      // get rid of alert message after 5 seconds
      setTimeout(() => {
        setAlertMessage("");
      }, 5000);
      return;
    }

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      setAlertType("success");
      setAlertMessage("Check your email for the confirmation link");

      if (error) throw error;

      // redirect to login page
    } catch (error) {
      setAlertType("error");
      setAlertMessage(error.error_description || error.message);
      // get rid of the alert after 5 seconds
      setTimeout(() => {
        setAlertMessage("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {alertMessage && (
        <Alert status={alertType}>
          <AlertIcon />
          <AlertTitle>
            {alertType === "error" ? "Sign up failed" : "Sign up successful"}
          </AlertTitle>
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
      <SignUpForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        handleSignup={handleSignup}
        loading={loading}
        setLoading={setLoading}
        signUpWithGoogle={signUpWithGoogle}
      />
    </>
  );
};

export default Signup;
