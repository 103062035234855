import React, { Fragment } from 'react';
import { line, area } from 'd3-shape';
import compose from 'recompose/compose';
import pure from 'recompose/pure';
import withPropsOnChange from 'recompose/withPropsOnChange';
import defaultProps from 'recompose/defaultProps';
import { blendModePropType, motionPropTypes, SmartMotion, TableTooltip, dotsThemePropType, getLabelGenerator, DotsItem, lineCurvePropType, withTheme, withColors, withDimensions, withMotion, curveFromProp, Container, Grid, CartesianMarkers, getInheritedColorGenerator, SvgWrapper, ResponsiveWrapper } from '@nivo/core';
import { axisPropType, Axes } from '@nivo/axes';
import { scalePropType, computeXYScalesForSeries, computeYSlices } from '@nivo/scales';
import { LegendPropShape, BoxLegendSvg } from '@nivo/legends';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { format } from 'd3-format';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import { TransitionMotion, spring } from 'react-motion';

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var LineAreas = function LineAreas(_ref) {
  var areaGenerator = _ref.areaGenerator,
      areaOpacity = _ref.areaOpacity,
      areaBlendMode = _ref.areaBlendMode,
      lines = _ref.lines,
      animate = _ref.animate,
      motionStiffness = _ref.motionStiffness,
      motionDamping = _ref.motionDamping;
  if (animate !== true) {
    return React.createElement("g", null, lines.slice(0).reverse().map(function (_ref2) {
      var id = _ref2.id,
          data = _ref2.data,
          areaColor = _ref2.color;
      return React.createElement("path", {
        key: id,
        d: areaGenerator(data.map(function (d) {
          return d.position;
        })),
        fill: areaColor,
        fillOpacity: areaOpacity,
        strokeWidth: 0,
        style: {
          mixBlendMode: areaBlendMode
        }
      });
    }));
  }
  var springConfig = {
    stiffness: motionStiffness,
    damping: motionDamping
  };
  return React.createElement("g", null, lines.slice(0).reverse().map(function (_ref3) {
    var id = _ref3.id,
        data = _ref3.data,
        areaColor = _ref3.color;
    return React.createElement(SmartMotion, {
      key: id,
      style: function style(spring) {
        return {
          d: spring(areaGenerator(data.map(function (d) {
            return d.position;
          })), springConfig),
          fill: spring(areaColor, springConfig)
        };
      }
    }, function (style) {
      return React.createElement("path", {
        key: id,
        d: style.d,
        fill: areaColor,
        fillOpacity: areaOpacity,
        strokeWidth: 0,
        style: {
          mixBlendMode: areaBlendMode
        }
      });
    });
  }));
};
LineAreas.propTypes = _objectSpread({
  areaOpacity: PropTypes.number.isRequired,
  areaBlendMode: blendModePropType.isRequired
}, motionPropTypes);
var LineAreas$1 = pure(LineAreas);

function _objectSpread$1(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$1(target, key, source[key]); }); } return target; }
function _defineProperty$1(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var LineLine = function LineLine(_ref) {
  var lineGenerator = _ref.lineGenerator,
      id = _ref.id,
      points = _ref.points,
      color = _ref.color,
      thickness = _ref.thickness,
      animate = _ref.animate,
      motionStiffness = _ref.motionStiffness,
      motionDamping = _ref.motionDamping;
  if (animate !== true) {
    return React.createElement("path", {
      key: id,
      d: lineGenerator(points),
      fill: "none",
      strokeWidth: thickness,
      stroke: color
    });
  }
  var springConfig = {
    stiffness: motionStiffness,
    damping: motionDamping
  };
  return React.createElement(SmartMotion, {
    key: id,
    style: function style(spring) {
      return {
        d: spring(lineGenerator(points), springConfig),
        stroke: spring(color, springConfig)
      };
    }
  }, function (style) {
    return React.createElement("path", {
      key: id,
      d: style.d,
      fill: "none",
      strokeWidth: thickness,
      stroke: style.stroke
    });
  });
};
LineLine.propTypes = _objectSpread$1({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  points: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  lineGenerator: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  thickness: PropTypes.number.isRequired
}, motionPropTypes);
var Line = pure(LineLine);

function _objectSpread$2(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$2(target, key, source[key]); }); } return target; }
function _defineProperty$2(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var LineLines = function LineLines(_ref) {
  var lines = _ref.lines,
      lineGenerator = _ref.lineGenerator,
      lineWidth = _ref.lineWidth,
      animate = _ref.animate,
      motionStiffness = _ref.motionStiffness,
      motionDamping = _ref.motionDamping;
  return React.createElement("g", null, lines.map(function (_ref2) {
    var id = _ref2.id,
        data = _ref2.data,
        color = _ref2.color;
    return React.createElement(Line, {
      key: id,
      id: id,
      points: data.map(function (d) {
        return d.position;
      }),
      lineGenerator: lineGenerator,
      color: color,
      thickness: lineWidth,
      animate: animate,
      motionStiffness: motionStiffness,
      motionDamping: motionDamping
    });
  }));
};
LineLines.propTypes = _objectSpread$2({
  lines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    color: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
      }).isRequired,
      position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      }).isRequired
    })).isRequired
  })).isRequired,
  lineWidth: PropTypes.number.isRequired,
  lineGenerator: PropTypes.func.isRequired
}, motionPropTypes);
var LineLines$1 = pure(LineLines);

function _objectSpread$3(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$3(target, key, source[key]); }); } return target; }
function _defineProperty$3(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var Chip = function Chip(_ref) {
  var color = _ref.color;
  return React.createElement("span", {
    style: {
      display: 'block',
      width: '12px',
      height: '12px',
      background: color
    }
  });
};
Chip.propTypes = {
  color: PropTypes.string.isRequired
};
var LineSlicesItem = function LineSlicesItem(_ref2) {
  var slice = _ref2.slice,
      height = _ref2.height,
      showTooltip = _ref2.showTooltip,
      hideTooltip = _ref2.hideTooltip,
      isHover = _ref2.isHover;
  return React.createElement("g", {
    transform: "translate(".concat(slice.x, ", 0)")
  }, isHover && React.createElement("line", {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: height,
    stroke: "#000",
    strokeOpacity: 0.35,
    strokeWidth: 1
  }), React.createElement("rect", {
    x: -20,
    width: 40,
    height: height,
    fill: "#F00",
    fillOpacity: 0,
    onMouseEnter: showTooltip,
    onMouseMove: showTooltip,
    onMouseLeave: hideTooltip
  }));
};
LineSlicesItem.propTypes = {
  slice: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  isHover: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
  tooltip: PropTypes.func,
  tooltipFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};
var enhance = compose(withState('isHover', 'setIsHover', false), withPropsOnChange(['slice', 'theme', 'tooltip', 'tooltipFormat'], function (_ref3) {
  var slice = _ref3.slice,
      theme = _ref3.theme,
      tooltip = _ref3.tooltip,
      tooltipFormat = _ref3.tooltipFormat;
  var format$1 = !tooltipFormat || isFunction(tooltipFormat) ? tooltipFormat : format(tooltipFormat);
  var hasValues = slice.data.some(function (d) {
    return d.position.x !== null && d.position.y !== null;
  });
  return {
    tooltipElement: hasValues ? React.createElement(TableTooltip, {
      theme: theme,
      rows: slice.data.filter(function (d) {
        return d.position.x !== null && d.position.y !== null;
      }).map(function (d) {
        return [React.createElement(Chip, {
          key: d.id,
          color: d.serie.color
        }), d.serie.id, format$1 ? format$1(d.data.y) : d.data.y];
      }),
      format: format$1,
      renderContent: typeof tooltip === 'function' ? tooltip.bind(null, _objectSpread$3({}, slice)) : null
    }) : null
  };
}), withHandlers({
  showTooltip: function showTooltip(_ref4) {
    var _showTooltip = _ref4.showTooltip,
        setIsHover = _ref4.setIsHover,
        tooltipElement = _ref4.tooltipElement;
    return function (e) {
      setIsHover(true);
      _showTooltip(tooltipElement, e);
    };
  },
  hideTooltip: function hideTooltip(_ref5) {
    var _hideTooltip = _ref5.hideTooltip,
        setIsHover = _ref5.setIsHover;
    return function () {
      setIsHover(false);
      _hideTooltip();
    };
  }
}), pure);
var LineSlicesItem$1 = enhance(LineSlicesItem);

var LineSlices = function LineSlices(_ref) {
  var slices = _ref.slices,
      height = _ref.height,
      showTooltip = _ref.showTooltip,
      hideTooltip = _ref.hideTooltip,
      theme = _ref.theme,
      tooltip = _ref.tooltip,
      tooltipFormat = _ref.tooltipFormat;
  return React.createElement("g", null, slices.map(function (slice) {
    return React.createElement(LineSlicesItem$1, {
      key: slice.id,
      slice: slice,
      height: height,
      showTooltip: showTooltip,
      hideTooltip: hideTooltip,
      theme: theme,
      tooltipFormat: tooltipFormat,
      tooltip: tooltip
    });
  }));
};
LineSlices.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    x: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
        y: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)])
      }),
      position: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      }).isRequired,
      serie: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        color: PropTypes.string.isRequired
      }).isRequired
    })).isRequired
  })).isRequired,
  height: PropTypes.number.isRequired,
  showTooltip: PropTypes.func.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  tooltip: PropTypes.func,
  tooltipFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};
var LineSlices$1 = pure(LineSlices);

function _objectSpread$4(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$4(target, key, source[key]); }); } return target; }
function _defineProperty$4(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }
function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }
var LineDots = function LineDots(_ref) {
  var lines = _ref.lines,
      symbol = _ref.symbol,
      size = _ref.size,
      color = _ref.color,
      borderWidth = _ref.borderWidth,
      borderColor = _ref.borderColor,
      enableLabel = _ref.enableLabel,
      label = _ref.label,
      labelFormat = _ref.labelFormat,
      labelYOffset = _ref.labelYOffset,
      theme = _ref.theme,
      animate = _ref.animate,
      motionStiffness = _ref.motionStiffness,
      motionDamping = _ref.motionDamping;
  var getLabel = getLabelGenerator(label, labelFormat);
  var points = lines.reduce(function (acc, line) {
    var id = line.id,
        data = line.data;
    return [].concat(_toConsumableArray(acc), _toConsumableArray(data.filter(function (datum) {
      return datum.position.x !== null && datum.position.y !== null;
    }).map(function (datum) {
      return {
        key: "".concat(id, ".").concat(datum.data.x),
        x: datum.position.x,
        y: datum.position.y,
        datum: datum,
        fill: color(line),
        stroke: borderColor(line),
        label: enableLabel ? getLabel(datum.data) : null
      };
    })));
  }, []);
  if (animate !== true) {
    return React.createElement("g", null, points.map(function (point) {
      return React.createElement(DotsItem, {
        key: point.key,
        x: point.x,
        y: point.y,
        datum: point.datum,
        symbol: symbol,
        size: size,
        color: point.fill,
        borderWidth: borderWidth,
        borderColor: point.stroke,
        label: point.label,
        labelYOffset: labelYOffset,
        theme: theme
      });
    }));
  }
  var springConfig = {
    motionDamping: motionDamping,
    motionStiffness: motionStiffness
  };
  return React.createElement(TransitionMotion, {
    styles: points.map(function (point) {
      return {
        key: point.key,
        data: point,
        style: {
          x: spring(point.x, springConfig),
          y: spring(point.y, springConfig),
          size: spring(size, springConfig)
        }
      };
    })
  }, function (interpolatedStyles) {
    return React.createElement("g", null, interpolatedStyles.map(function (_ref2) {
      var key = _ref2.key,
          style = _ref2.style,
          point = _ref2.data;
      return React.createElement(DotsItem, _extends({
        key: key
      }, style, {
        symbol: symbol,
        datum: point.datum,
        color: point.fill,
        borderWidth: borderWidth,
        borderColor: point.stroke,
        label: point.label,
        labelYOffset: labelYOffset,
        theme: theme
      }));
    }));
  });
};
LineDots.propTypes = _objectSpread$4({
  lines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })),
  symbol: PropTypes.func,
  size: PropTypes.number.isRequired,
  color: PropTypes.func.isRequired,
  borderWidth: PropTypes.number.isRequired,
  borderColor: PropTypes.func.isRequired,
  enableLabel: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  labelFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  labelYOffset: PropTypes.number,
  theme: PropTypes.shape({
    dots: dotsThemePropType.isRequired
  }).isRequired
}, motionPropTypes);
LineDots.defaultProps = {
  enableLabel: false,
  label: 'y'
};

var LinePropTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
      y: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)])
    })).isRequired
  })).isRequired,
  xScale: scalePropType.isRequired,
  yScale: scalePropType.isRequired,
  computedData: PropTypes.object.isRequired,
  layers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.oneOf(['grid', 'markers', 'axes', 'areas', 'lines', 'slices', 'dots', 'legends']), PropTypes.func])).isRequired,
  curve: lineCurvePropType.isRequired,
  areaGenerator: PropTypes.func.isRequired,
  lineGenerator: PropTypes.func.isRequired,
  axisTop: axisPropType,
  axisRight: axisPropType,
  axisBottom: axisPropType,
  axisLeft: axisPropType,
  enableGridX: PropTypes.bool.isRequired,
  enableGridY: PropTypes.bool.isRequired,
  gridXValues: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]))]),
  gridYValues: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]))]),
  enableDots: PropTypes.bool.isRequired,
  dotSymbol: PropTypes.func,
  dotSize: PropTypes.number.isRequired,
  dotColor: PropTypes.any.isRequired,
  dotBorderWidth: PropTypes.number.isRequired,
  dotBorderColor: PropTypes.any.isRequired,
  enableDotLabel: PropTypes.bool.isRequired,
  markers: PropTypes.arrayOf(PropTypes.shape({
    axis: PropTypes.oneOf(['x', 'y']).isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    style: PropTypes.object
  })),
  getColor: PropTypes.func.isRequired,
  enableArea: PropTypes.bool.isRequired,
  areaOpacity: PropTypes.number.isRequired,
  areaBlendMode: blendModePropType.isRequired,
  areaBaselineValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  lineWidth: PropTypes.number.isRequired,
  defs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  isInteractive: PropTypes.bool.isRequired,
  enableStackTooltip: PropTypes.bool.isRequired,
  tooltip: PropTypes.func,
  tooltipFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  legends: PropTypes.arrayOf(PropTypes.shape(LegendPropShape)).isRequired
};
var LineDefaultProps = {
  curve: 'linear',
  xScale: {
    type: 'point'
  },
  yScale: {
    type: 'linear',
    min: 0,
    max: 'auto'
  },
  layers: ['grid', 'markers', 'axes', 'areas', 'lines', 'slices', 'dots', 'legends'],
  axisBottom: {},
  axisLeft: {},
  enableGridX: true,
  enableGridY: true,
  enableDots: true,
  dotSize: 6,
  dotColor: 'inherit',
  dotBorderWidth: 0,
  dotBorderColor: 'inherit',
  enableDotLabel: false,
  colors: 'nivo',
  colorBy: 'id',
  enableArea: false,
  areaBaselineValue: 0,
  areaOpacity: 0.2,
  areaBlendMode: 'normal',
  lineWidth: 2,
  defs: [],
  isInteractive: true,
  enableStackTooltip: true,
  legends: []
};

function _objectSpread$5(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$5(target, key, source[key]); }); } return target; }
function _defineProperty$5(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _extends$1() { _extends$1 = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends$1.apply(this, arguments); }
var Line$1 = function Line(props) {
  var computedData = props.computedData,
      lineGenerator = props.lineGenerator,
      areaGenerator = props.areaGenerator,
      layers = props.layers,
      margin = props.margin,
      width = props.width,
      height = props.height,
      outerWidth = props.outerWidth,
      outerHeight = props.outerHeight,
      axisTop = props.axisTop,
      axisRight = props.axisRight,
      axisBottom = props.axisBottom,
      axisLeft = props.axisLeft,
      enableGridX = props.enableGridX,
      enableGridY = props.enableGridY,
      gridXValues = props.gridXValues,
      gridYValues = props.gridYValues,
      lineWidth = props.lineWidth,
      enableArea = props.enableArea,
      areaOpacity = props.areaOpacity,
      areaBlendMode = props.areaBlendMode,
      enableDots = props.enableDots,
      dotSymbol = props.dotSymbol,
      dotSize = props.dotSize,
      dotColor = props.dotColor,
      dotBorderWidth = props.dotBorderWidth,
      dotBorderColor = props.dotBorderColor,
      enableDotLabel = props.enableDotLabel,
      dotLabel = props.dotLabel,
      dotLabelFormat = props.dotLabelFormat,
      dotLabelYOffset = props.dotLabelYOffset,
      markers = props.markers,
      theme = props.theme,
      animate = props.animate,
      motionStiffness = props.motionStiffness,
      motionDamping = props.motionDamping,
      isInteractive = props.isInteractive,
      tooltipFormat = props.tooltipFormat,
      tooltip = props.tooltip,
      enableStackTooltip = props.enableStackTooltip,
      legends = props.legends;
  var motionProps = {
    animate: animate,
    motionDamping: motionDamping,
    motionStiffness: motionStiffness
  };
  var legendData = computedData.series.map(function (line) {
    return {
      id: line.id,
      label: line.id,
      color: line.color
    };
  }).reverse();
  return React.createElement(Container, {
    isInteractive: isInteractive,
    theme: theme
  }, function (_ref) {
    var showTooltip = _ref.showTooltip,
        hideTooltip = _ref.hideTooltip;
    var layerById = {
      grid: React.createElement(Grid, _extends$1({
        key: "grid",
        theme: theme,
        width: width,
        height: height,
        xScale: enableGridX ? computedData.xScale : null,
        yScale: enableGridY ? computedData.yScale : null,
        xValues: gridXValues,
        yValues: gridYValues
      }, motionProps)),
      markers: React.createElement(CartesianMarkers, {
        key: "markers",
        markers: markers,
        width: width,
        height: height,
        xScale: computedData.xScale,
        yScale: computedData.yScale,
        theme: theme
      }),
      axes: React.createElement(Axes, _extends$1({
        key: "axes",
        xScale: computedData.xScale,
        yScale: computedData.yScale,
        width: width,
        height: height,
        theme: theme,
        top: axisTop,
        right: axisRight,
        bottom: axisBottom,
        left: axisLeft
      }, motionProps)),
      areas: null,
      lines: React.createElement(LineLines$1, _extends$1({
        key: "lines",
        lines: computedData.series,
        lineGenerator: lineGenerator,
        lineWidth: lineWidth
      }, motionProps)),
      slices: null,
      dots: null,
      legends: legends.map(function (legend, i) {
        return React.createElement(BoxLegendSvg, _extends$1({
          key: i
        }, legend, {
          containerWidth: width,
          containerHeight: height,
          data: legendData,
          theme: theme
        }));
      })
    };
    if (enableArea) {
      layerById.areas = React.createElement(LineAreas$1, _extends$1({
        key: "areas",
        areaGenerator: areaGenerator,
        areaOpacity: areaOpacity,
        areaBlendMode: areaBlendMode,
        lines: computedData.series
      }, motionProps));
    }
    if (isInteractive && enableStackTooltip) {
      layerById.slices = React.createElement(LineSlices$1, {
        key: "slices",
        slices: computedData.slices,
        height: height,
        showTooltip: showTooltip,
        hideTooltip: hideTooltip,
        theme: theme,
        tooltipFormat: tooltipFormat,
        tooltip: tooltip
      });
    }
    if (enableDots) {
      layerById.dots = React.createElement(LineDots, _extends$1({
        key: "dots",
        lines: computedData.series,
        symbol: dotSymbol,
        size: dotSize,
        color: getInheritedColorGenerator(dotColor),
        borderWidth: dotBorderWidth,
        borderColor: getInheritedColorGenerator(dotBorderColor),
        enableLabel: enableDotLabel,
        label: dotLabel,
        labelFormat: dotLabelFormat,
        labelYOffset: dotLabelYOffset,
        theme: theme
      }, motionProps));
    }
    return React.createElement(SvgWrapper, {
      width: outerWidth,
      height: outerHeight,
      margin: margin,
      theme: theme
    }, layers.map(function (layer, i) {
      if (typeof layer === 'function') {
        return React.createElement(Fragment, {
          key: i
        }, layer(_objectSpread$5({}, props, {
          xScale: computedData.xScale,
          yScale: computedData.yScale,
          showTooltip: showTooltip,
          hideTooltip: hideTooltip
        })));
      }
      return layerById[layer];
    }));
  });
};
Line$1.propTypes = LinePropTypes;
var enhance$1 = compose(defaultProps(LineDefaultProps), withTheme(), withColors(), withDimensions(), withMotion(), withPropsOnChange(['curve'], function (_ref2) {
  var curve = _ref2.curve;
  return {
    lineGenerator: line().defined(function (d) {
      return d.x !== null && d.y !== null;
    }).x(function (d) {
      return d.x;
    }).y(function (d) {
      return d.y;
    }).curve(curveFromProp(curve))
  };
}), withPropsOnChange(['data', 'xScale', 'yScale', 'width', 'height'], function (_ref3) {
  var data = _ref3.data,
      xScale = _ref3.xScale,
      yScale = _ref3.yScale,
      width = _ref3.width,
      height = _ref3.height;
  return {
    computedData: computeXYScalesForSeries(data, xScale, yScale, width, height)
  };
}), withPropsOnChange(['getColor', 'computedData'], function (_ref4) {
  var getColor = _ref4.getColor,
      _computedData = _ref4.computedData;
  var computedData = _objectSpread$5({}, _computedData, {
    series: _computedData.series.map(function (serie) {
      return _objectSpread$5({}, serie, {
        color: getColor(serie)
      });
    })
  });
  computedData.slices = computeYSlices(computedData);
  return {
    computedData: computedData
  };
}), withPropsOnChange(['curve', 'computedData', 'areaBaselineValue'], function (_ref5) {
  var curve = _ref5.curve,
      computedData = _ref5.computedData,
      areaBaselineValue = _ref5.areaBaselineValue;
  return {
    areaGenerator: area().defined(function (d) {
      return d.x !== null && d.y !== null;
    }).x(function (d) {
      return d.x;
    }).y1(function (d) {
      return d.y;
    }).curve(curveFromProp(curve)).y0(computedData.yScale(areaBaselineValue))
  };
}), pure);
var enhancedLine = enhance$1(Line$1);
enhancedLine.displayName = 'Line';

function _extends$2() { _extends$2 = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends$2.apply(this, arguments); }
var ResponsiveLine = function ResponsiveLine(props) {
  return React.createElement(ResponsiveWrapper, null, function (_ref) {
    var width = _ref.width,
        height = _ref.height;
    return React.createElement(enhancedLine, _extends$2({
      width: width,
      height: height
    }, props));
  });
};

export { enhancedLine as Line, LineDefaultProps, LinePropTypes, ResponsiveLine };
