import React, { ReactNode, useEffect, useRef, useState } from "react";
import type { PromptData } from "../TypingMain";
import { Avatar, Card, Flex, Link, Text } from "@chakra-ui/react";

interface ITypingInterfaceProps {
  keyHandler: (e: React.KeyboardEvent<HTMLInputElement>, promptData) => void;
  promptData: PromptData;
  focusInput: () => void;
  setPromptData: (promptData: PromptData) => void;
}

const TypingInterface = ({
  keyHandler,
  promptData,
  focusInput,
  setPromptData,
}: ITypingInterfaceProps) => {
  const [divHeight, setDivHeight] = useState(0);

  const promptContainer = useRef<HTMLDivElement>(null);
  if (!promptData.prompt || !promptData.prompt.quote) return <div>Loading</div>;

  function isInputFocused() {
    return (
      document.activeElement?.classList.contains("hiddenTextInput") ?? false
    );
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isInputFocused()) return;

    const keyCode = e.keyCode;
    const time = new Date().getTime();

    const copyOfKeyPresses = [...promptData.keyPresses];

    const unReleasedKeyPresses = copyOfKeyPresses.filter(
      (keyPress) => keyPress.releaseTime === null
    );

    // find the keypress that matches the key that was just released
    const keyPressToRelease = unReleasedKeyPresses.find(
      (keyPress) => keyPress.keyCode === keyCode
    );

    if (!keyPressToRelease) return;
    keyPressToRelease.releaseTime = time;

    // send alert if any keypress in copyOfKeyPresses has a releastTime

    setPromptData({
      ...promptData,
      keyPresses: copyOfKeyPresses,
    });
  };

  return (
    <>
      <input
        type="text"
        autoFocus={true}
        onKeyDown={(e) => {
          keyHandler(e, promptData);
        }}
        className="hiddenTextInput"
        onKeyUp={onKeyUp}
      />
      <div
        className="blurText notSelectable"
        onClick={focusInput}
        style={{
          top: divHeight / 2,
        }}
      >
        Click to activate...
      </div>
      <TypingCard
        promptData={promptData}
        focusInput={focusInput}
        statsMode={false}
        promptContainer={promptContainer}
        setDivHeight={setDivHeight}
      />
    </>
  );
};

interface ITypingCardProps {
  promptData: PromptData;
  focusInput: () => void;
  statsMode: boolean;
  promptContainer?: React.RefObject<HTMLDivElement>;
  setDivHeight?: (height: number) => void;
}

export const TypingCard = ({
  promptData,
  focusInput,
  statsMode,
  promptContainer,
  setDivHeight,
}: ITypingCardProps) => {
  function displayPromptWithErrors(
    prompt: string,
    userInput: string[],
    userCorrectIndex: number
  ) {
    const promptChars = prompt.split("");
    const userInputChars = userInput;
    let result: ReactNode[] = [];

    let promptCharsIndex = 0;
    let userInputCharsIndex = 0;

    while (userInputCharsIndex < userInputChars.length) {
      const promptChar = promptChars[promptCharsIndex];
      const userInputChar = userInputChars[userInputCharsIndex];

      let className = "";

      if (userInputChar !== promptChar) {
        className += " errorChar";
      } else {
        className += " seenChar";
      }

      result.push(
        <span className={className}>
          {userInputChar !== promptChar && userInputChar == " "
            ? "_"
            : userInputChar}
        </span>
      );

      promptCharsIndex++;
      userInputCharsIndex++;
    }

    let i = 0;
    while (promptCharsIndex < promptChars.length) {
      const promptChar = promptChars[promptCharsIndex];

      result.push(
        <span className={i++ == 0 ? "currentChar" : "unseenChar"}>
          {promptChar}
        </span>
      );

      promptCharsIndex++;
    }

    return result;
  }

  useEffect(() => {
    if (!promptContainer || !setDivHeight) return;
    if (promptContainer.current) {
      const divHeight = promptContainer.current.clientHeight;
      setDivHeight(divHeight);
    }
  }, [promptData]);

  return (
    <Card
      variant={"elevated"}
      rounded="3xl"
      shadow={"2xl"}
      className="promptCard"
      onClick={focusInput}
      onClickCapture={focusInput}
      onFocus={focusInput}
      onMouseDown={focusInput}
      width={statsMode ? "min(80vw, 700px)" : undefined}
      ref={promptContainer}
    >
      <div className="prompt">
        {displayPromptWithErrors(
          promptData.prompt.quote,
          promptData.userInput,
          promptData.userCorrectIndex
        )}
      </div>
      <Flex
        gap={"1rem"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        width={"100%"}
      >
        <Link
          isExternal={true}
          href={promptData.prompt.source}
          color={"blue.500"}
        >
          {promptData.prompt.type}
        </Link>
        <Text>
          By{" "}
          <Link
            isExternal={true}
            href={promptData.prompt.author_link}
            color={"blue.500"}
          >
            {promptData.prompt.author}
          </Link>
        </Text>
        <Avatar src={promptData.prompt.author_image} />
      </Flex>
    </Card>
  );
};

export default TypingInterface;

type PromptCharacterClassStyles =
  | "seenChar"
  | "currentChar"
  | "unseenChar"
  | "errorChar";
