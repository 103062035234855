import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Container,
  useColorModeValue,
  useDisclosure,
  Text,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { Session } from "@supabase/supabase-js";
import React from "react";
import { supabase } from "../../utils/supabaseClient";
import { FiTrash2 } from "react-icons/fi";

interface DeleteAccountProps {
  session: Session | null;
}

const DeleteAccount = ({ session }: DeleteAccountProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  const handleDelete = async () => {
    try {
      const user = session?.user;
      if (user) {
        const { data, error } = await supabase
          .from("deletion_requests")
          .insert([{ user_id: user.id }]);

        if (error) {
          throw error;
        }

        // Sign the user out after successful deletion request
        await supabase.auth.signOut();
        onClose(); // Close the confirmation dialog

        // Redirect the user to a different page or show a success message
      }
    } catch (error) {
      console.error("Error submitting deletion request:", error.message);
      // Show an error message or handle the error in any other way
    }
  };
  return (
    <Container padding={0}>
      <Flex gap={3} alignItems={"center"} justifyContent={"center"}>
        <Text>Delete your account</Text>
        <Spacer />
        <Button
          background={useColorModeValue("red.500", "red.300")}
          color={useColorModeValue("white", "gray.800")}
          onClick={onOpen}
          gap={2}
        >
          <FiTrash2 /> <Text>Delete Account</Text>
        </Button>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Your Account
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  handleDelete();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default DeleteAccount;
