import React from "react";
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Card,
  CardBody,
  useColorModeValue,
} from "@chakra-ui/react";

interface IGenericStatProps {
  label: string;
  number: string;
  helpText?: string;
}

const GenericStat = ({ label, number, helpText }: IGenericStatProps) => {
  return (
    <Card
      variant={"elevated"}
      rounded="3xl"
      className="notSelectable"
      shadow={"2xl"}
      bg={useColorModeValue("white", "gray.800")}
    >
      <CardBody>
        <Stat>
          <StatLabel>{label}</StatLabel>
          <StatNumber>{number}</StatNumber>
          {helpText && <StatHelpText>{helpText}</StatHelpText>}
        </Stat>
      </CardBody>
    </Card>
  );
};

export default GenericStat;
