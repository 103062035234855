import React from "react";
import type { History } from "TypingMain";
interface HistoryProps {
  history: History;
  session: Session | null;
}

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Center,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Session } from "@supabase/supabase-js";

const History = ({ history, session }: HistoryProps) => {
  const navigate = useNavigate();
  const downloadTypingHistory = () => {
    const json = JSON.stringify(history, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "typing-history.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  return (
    <Center>
      <Container
        minW={"min(80vw, 700px)"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={6}
      >
        <Button
          background={useColorModeValue("yellow.200", "yellow.100")}
          color={useColorModeValue("yellow.800", "yellow.900")}
          onClick={downloadTypingHistory}
        >
          Download All Data
        </Button>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Date</Th>
              <Th>Time</Th>
              <Th isNumeric>WPM</Th>
              <Th>Prompt</Th>
              <Th>Author</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {history
              .sort((a, b) => b.startTime.getTime() - a.startTime.getTime())
              .map((entry) => (
                <Tr>
                  <Td>{entry.id}</Td>
                  <Td>{entry.startTime.toDateString()}</Td>
                  <Td>{entry.startTime.toLocaleTimeString()}</Td>
                  <Td>{entry.wpm}</Td>
                  <Td>{entry.prompt.quote.slice(0, 15)}...</Td>
                  <Td>{entry.prompt.author}</Td>
                  <Td>
                    <Button
                      variant={"outline"}
                      background={useColorModeValue("green.200", "green.100")}
                      color={useColorModeValue("green.800", "green.900")}
                      onClick={() => navigate(`/history/${entry.id}`)}
                    >
                      View Data
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Container>
    </Center>
  );
};

export default History;
