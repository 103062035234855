import React, { PureComponent, Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { textPropsByEngine, axisThemePropType, motionPropTypes, withMotion } from '@nivo/core';
import compose from 'recompose/compose';
import withPropsOnChange from 'recompose/withPropsOnChange';
import pure from 'recompose/pure';
import setDisplayName from 'recompose/setDisplayName';
import { Motion, spring, TransitionMotion } from 'react-motion';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var centerScale = function centerScale(scale) {
  var bandwidth = scale.bandwidth();
  if (bandwidth === 0) return scale;
  var offset = bandwidth / 2;
  if (scale.round()) {
    offset = Math.round(offset);
  }
  return function (d) {
    return scale(d) + offset;
  };
};
var getScaleTicks = function getScaleTicks(scale, tickCount) {
  if (scale.ticks) return scale.ticks(tickCount);
  return scale.domain();
};
var computeCartesianTicks = function computeCartesianTicks(_ref) {
  var axis = _ref.axis,
      scale = _ref.scale,
      ticksPosition = _ref.ticksPosition,
      _tickValues = _ref.tickValues,
      tickSize = _ref.tickSize,
      tickPadding = _ref.tickPadding,
      tickRotation = _ref.tickRotation,
      _ref$engine = _ref.engine,
      engine = _ref$engine === void 0 ? 'svg' : _ref$engine;
  var tickValues = isArray(_tickValues) ? _tickValues : undefined;
  var tickCount = isNumber(_tickValues) ? _tickValues : undefined;
  var values = tickValues || getScaleTicks(scale, tickCount);
  var textProps = textPropsByEngine[engine];
  var position = scale.bandwidth ? centerScale(scale) : scale;
  var line = {
    lineX: 0,
    lineY: 0
  };
  var text = {
    textX: 0,
    textY: 0
  };
  var translate;
  var textAlign = textProps.align.center;
  var textBaseline = textProps.baseline.center;
  if (axis === 'x') {
    translate = function translate(d) {
      return {
        x: position(d),
        y: 0
      };
    };
    line.lineY = tickSize * (ticksPosition === 'after' ? 1 : -1);
    text.textY = (tickSize + tickPadding) * (ticksPosition === 'after' ? 1 : -1);
    if (ticksPosition === 'after') {
      textBaseline = textProps.baseline.top;
    } else {
      textBaseline = textProps.baseline.bottom;
    }
    if (tickRotation === 0) {
      textAlign = textProps.align.center;
    } else if (ticksPosition === 'after' && tickRotation < 0 || ticksPosition === 'before' && tickRotation > 0) {
      textAlign = textProps.align.right;
      textBaseline = textProps.baseline.center;
    } else if (ticksPosition === 'after' && tickRotation > 0 || ticksPosition === 'before' && tickRotation < 0) {
      textAlign = textProps.align.left;
      textBaseline = textProps.baseline.center;
    }
  } else {
    translate = function translate(d) {
      return {
        x: 0,
        y: position(d)
      };
    };
    line.lineX = tickSize * (ticksPosition === 'after' ? 1 : -1);
    text.textX = (tickSize + tickPadding) * (ticksPosition === 'after' ? 1 : -1);
    if (ticksPosition === 'after') {
      textAlign = textProps.align.left;
    } else {
      textAlign = textProps.align.right;
    }
  }
  var ticks = values.map(function (value) {
    return _objectSpread({
      key: value,
      value: value
    }, translate(value), line, text);
  });
  return {
    ticks: ticks,
    textAlign: textAlign,
    textBaseline: textBaseline
  };
};
var getFormatter = function getFormatter(format$1, scale) {
  if (!format$1 || isFunction(format$1)) return format$1;
  if (scale.type === 'time') {
    var f = timeFormat(format$1);
    return function (d) {
      return f(new Date(d));
    };
  }
  return format(format$1);
};

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }
function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); return Constructor; }
function _possibleConstructorReturn(self, call) { if (call && (_typeof(call) === "object" || typeof call === "function")) { return call; } return _assertThisInitialized(self); }
function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }
function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf(subClass, superClass); }
function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }
function _defineProperty$1(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var AxisTick =
function (_PureComponent) {
  _inherits(AxisTick, _PureComponent);
  function AxisTick() {
    _classCallCheck(this, AxisTick);
    return _possibleConstructorReturn(this, _getPrototypeOf(AxisTick).apply(this, arguments));
  }
  _createClass(AxisTick, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          _value = _this$props.value,
          x = _this$props.x,
          y = _this$props.y,
          opacity = _this$props.opacity,
          rotate = _this$props.rotate,
          format = _this$props.format,
          lineX = _this$props.lineX,
          lineY = _this$props.lineY,
          _onClick = _this$props.onClick,
          textX = _this$props.textX,
          textY = _this$props.textY,
          textBaseline = _this$props.textBaseline,
          textAnchor = _this$props.textAnchor,
          theme = _this$props.theme;
      var value = _value;
      if (format !== undefined) {
        value = format(value);
      }
      var gStyle = {
        opacity: opacity
      };
      if (_onClick) {
        gStyle['cursor'] = 'pointer';
      }
      return React.createElement("g", _extends({
        transform: "translate(".concat(x, ",").concat(y, ")")
      }, _onClick ? {
        onClick: function onClick(e) {
          return _onClick(e, value);
        }
      } : {}, {
        style: gStyle
      }), React.createElement("line", {
        x1: 0,
        x2: lineX,
        y1: 0,
        y2: lineY,
        style: theme.axis.ticks.line
      }), React.createElement("text", {
        alignmentBaseline: textBaseline,
        textAnchor: textAnchor,
        transform: "translate(".concat(textX, ",").concat(textY, ") rotate(").concat(rotate, ")"),
        style: theme.axis.ticks.text
      }, value));
    }
  }]);
  return AxisTick;
}(PureComponent);
_defineProperty$1(AxisTick, "propTypes", {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  format: PropTypes.func,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  lineX: PropTypes.number.isRequired,
  lineY: PropTypes.number.isRequired,
  textX: PropTypes.number.isRequired,
  textY: PropTypes.number.isRequired,
  textBaseline: PropTypes.string.isRequired,
  textAnchor: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  rotate: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  theme: PropTypes.shape({
    axis: axisThemePropType.isRequired
  }).isRequired
});
_defineProperty$1(AxisTick, "defaultProps", {
  opacity: 1,
  rotate: 0
});

function _typeof$1(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof$1 = function _typeof(obj) { return typeof obj; }; } else { _typeof$1 = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof$1(obj); }
function _extends$1() { _extends$1 = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends$1.apply(this, arguments); }
function _objectSpread$1(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$2(target, key, source[key]); }); } return target; }
function _classCallCheck$1(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties$1(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }
function _createClass$1(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties$1(Constructor.prototype, protoProps); if (staticProps) _defineProperties$1(Constructor, staticProps); return Constructor; }
function _possibleConstructorReturn$1(self, call) { if (call && (_typeof$1(call) === "object" || typeof call === "function")) { return call; } return _assertThisInitialized$1(self); }
function _assertThisInitialized$1(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _getPrototypeOf$1(o) { _getPrototypeOf$1 = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf$1(o); }
function _inherits$1(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf$1(subClass, superClass); }
function _setPrototypeOf$1(o, p) { _setPrototypeOf$1 = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf$1(o, p); }
function _defineProperty$2(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var willEnter = function willEnter() {
  return {
    rotate: 0,
    opacity: 0,
    x: 0,
    y: 0
  };
};
var willLeave = function willLeave(springConfig) {
  return function (_ref) {
    var _ref$style = _ref.style,
        x = _ref$style.x,
        y = _ref$style.y,
        rotate = _ref$style.rotate;
    return {
      rotate: rotate,
      opacity: spring(0, springConfig),
      x: spring(x.val, springConfig),
      y: spring(y.val, springConfig)
    };
  };
};
var defaultTickRenderer = function defaultTickRenderer(props) {
  return React.createElement(AxisTick, props);
};
var Axis =
function (_Component) {
  _inherits$1(Axis, _Component);
  function Axis() {
    _classCallCheck$1(this, Axis);
    return _possibleConstructorReturn$1(this, _getPrototypeOf$1(Axis).apply(this, arguments));
  }
  _createClass$1(Axis, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          axis = _this$props.axis,
          scale = _this$props.scale,
          x = _this$props.x,
          y = _this$props.y,
          length = _this$props.length,
          ticksPosition = _this$props.ticksPosition,
          tickValues = _this$props.tickValues,
          tickSize = _this$props.tickSize,
          tickPadding = _this$props.tickPadding,
          tickRotation = _this$props.tickRotation,
          format = _this$props.format,
          renderTick = _this$props.renderTick,
          legend = _this$props.legend,
          legendPosition = _this$props.legendPosition,
          legendOffset = _this$props.legendOffset,
          theme = _this$props.theme,
          animate = _this$props.animate,
          motionStiffness = _this$props.motionStiffness,
          motionDamping = _this$props.motionDamping,
          onClick = _this$props.onClick;
      var _computeCartesianTick = computeCartesianTicks({
        axis: axis,
        scale: scale,
        ticksPosition: ticksPosition,
        tickValues: tickValues,
        tickSize: tickSize,
        tickPadding: tickPadding,
        tickRotation: tickRotation
      }),
          ticks = _computeCartesianTick.ticks,
          textAlign = _computeCartesianTick.textAlign,
          textBaseline = _computeCartesianTick.textBaseline;
      var legendNode = null;
      if (legend !== undefined) {
        var legendX = 0;
        var legendY = 0;
        var legendRotation = 0;
        var textAnchor;
        if (axis === 'y') {
          legendRotation = -90;
          legendX = legendOffset;
          if (legendPosition === 'start') {
            textAnchor = 'start';
            legendY = length;
          } else if (legendPosition === 'middle') {
            textAnchor = 'middle';
            legendY = length / 2;
          } else if (legendPosition === 'end') {
            textAnchor = 'end';
          }
        } else {
          legendY = legendOffset;
          if (legendPosition === 'start') {
            textAnchor = 'start';
          } else if (legendPosition === 'middle') {
            textAnchor = 'middle';
            legendX = length / 2;
          } else if (legendPosition === 'end') {
            textAnchor = 'end';
            legendX = length;
          }
        }
        legendNode = React.createElement("text", {
          transform: "translate(".concat(legendX, ", ").concat(legendY, ") rotate(").concat(legendRotation, ")"),
          textAnchor: textAnchor,
          style: _objectSpread$1({
            alignmentBaseline: 'middle'
          }, theme.axis.legend.text)
        }, legend);
      }
      if (animate !== true) {
        return React.createElement("g", {
          transform: "translate(".concat(x, ",").concat(y, ")")
        }, ticks.map(function (tick, tickIndex) {
          return renderTick(_objectSpread$1({
            tickIndex: tickIndex,
            format: format,
            rotate: tickRotation,
            textBaseline: textBaseline,
            textAnchor: textAlign,
            theme: theme
          }, tick, onClick ? {
            onClick: onClick
          } : {}));
        }), React.createElement("line", {
          style: theme.axis.domain.line,
          x1: 0,
          x2: axis === 'x' ? length : 0,
          y1: 0,
          y2: axis === 'x' ? 0 : length
        }), legendNode);
      }
      var springConfig = {
        stiffness: motionStiffness,
        damping: motionDamping
      };
      return React.createElement(Motion, {
        style: {
          x: spring(x, springConfig),
          y: spring(y, springConfig)
        }
      }, function (xy) {
        return React.createElement("g", {
          transform: "translate(".concat(xy.x, ",").concat(xy.y, ")")
        }, React.createElement(TransitionMotion, {
          willEnter: willEnter,
          willLeave: willLeave(springConfig),
          styles: ticks.map(function (tick) {
            return {
              key: "".concat(tick.key),
              data: tick,
              style: {
                opacity: spring(1, springConfig),
                x: spring(tick.x, springConfig),
                y: spring(tick.y, springConfig),
                rotate: spring(tickRotation, springConfig)
              }
            };
          })
        }, function (interpolatedStyles) {
          return React.createElement(Fragment, null, interpolatedStyles.map(function (_ref2, tickIndex) {
            var style = _ref2.style,
                tick = _ref2.data;
            return renderTick(_objectSpread$1({
              tickIndex: tickIndex,
              format: format,
              textBaseline: textBaseline,
              textAnchor: textAlign,
              theme: theme
            }, tick, style, onClick ? {
              onClick: onClick
            } : {}));
          }));
        }), React.createElement(Motion, {
          style: {
            x2: spring(axis === 'x' ? length : 0, springConfig),
            y2: spring(axis === 'x' ? 0 : length, springConfig)
          }
        }, function (values) {
          return React.createElement("line", _extends$1({
            style: theme.axis.domain.line,
            x1: 0,
            y1: 0
          }, values));
        }), legendNode);
      });
    }
  }]);
  return Axis;
}(Component);
_defineProperty$2(Axis, "propTypes", _objectSpread$1({
  axis: PropTypes.oneOf(['x', 'y']).isRequired,
  scale: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  ticksPosition: PropTypes.oneOf(['before', 'after']).isRequired,
  tickValues: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]))]),
  tickSize: PropTypes.number.isRequired,
  tickPadding: PropTypes.number.isRequired,
  tickRotation: PropTypes.number.isRequired,
  tickFormat: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  renderTick: PropTypes.func.isRequired,
  legend: PropTypes.node,
  legendPosition: PropTypes.oneOf(['start', 'middle', 'end']).isRequired,
  legendOffset: PropTypes.number.isRequired,
  theme: PropTypes.shape({
    axis: axisThemePropType.isRequired
  }).isRequired
}, motionPropTypes));
_defineProperty$2(Axis, "defaultProps", {
  x: 0,
  y: 0,
  tickSize: 5,
  tickPadding: 5,
  tickRotation: 0,
  renderTick: defaultTickRenderer,
  legendPosition: 'end',
  legendOffset: 0
});
var enhance = compose(withMotion(), withPropsOnChange(['format', 'scale'], function (_ref3) {
  var format = _ref3.format,
      scale = _ref3.scale;
  return {
    format: getFormatter(format, scale)
  };
}), pure);
var Axis$1 = setDisplayName('Axis')(enhance(Axis));

var axisPropTypes = {
  ticksPosition: PropTypes.oneOf(['before', 'after']),
  tickValues: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]))]),
  tickSize: PropTypes.number,
  tickPadding: PropTypes.number,
  tickRotation: PropTypes.number,
  format: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  renderTick: PropTypes.func,
  legend: PropTypes.node,
  legendPosition: PropTypes.oneOf(['start', 'middle', 'end']),
  legendOffset: PropTypes.number
};
var axisPropType = PropTypes.shape(axisPropTypes);

function _objectSpread$2(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$3(target, key, source[key]); }); } return target; }
function _typeof$2(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof$2 = function _typeof(obj) { return typeof obj; }; } else { _typeof$2 = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof$2(obj); }
function _extends$2() { _extends$2 = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends$2.apply(this, arguments); }
function _classCallCheck$2(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties$2(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } }
function _createClass$2(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties$2(Constructor.prototype, protoProps); if (staticProps) _defineProperties$2(Constructor, staticProps); return Constructor; }
function _possibleConstructorReturn$2(self, call) { if (call && (_typeof$2(call) === "object" || typeof call === "function")) { return call; } return _assertThisInitialized$2(self); }
function _assertThisInitialized$2(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _getPrototypeOf$2(o) { _getPrototypeOf$2 = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf$2(o); }
function _inherits$2(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); if (superClass) _setPrototypeOf$2(subClass, superClass); }
function _setPrototypeOf$2(o, p) { _setPrototypeOf$2 = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf$2(o, p); }
function _defineProperty$3(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var positions = ['top', 'right', 'bottom', 'left'];
var Axes =
function (_PureComponent) {
  _inherits$2(Axes, _PureComponent);
  function Axes() {
    _classCallCheck$2(this, Axes);
    return _possibleConstructorReturn$2(this, _getPrototypeOf$2(Axes).apply(this, arguments));
  }
  _createClass$2(Axes, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          xScale = _this$props.xScale,
          yScale = _this$props.yScale,
          width = _this$props.width,
          height = _this$props.height,
          top = _this$props.top,
          right = _this$props.right,
          bottom = _this$props.bottom,
          left = _this$props.left,
          theme = _this$props.theme,
          animate = _this$props.animate,
          motionStiffness = _this$props.motionStiffness,
          motionDamping = _this$props.motionDamping;
      var axes = {
        top: top,
        right: right,
        bottom: bottom,
        left: left
      };
      return React.createElement(Fragment, null, positions.map(function (position) {
        var axis = axes[position];
        if (!axis) return null;
        var isXAxis = position === 'top' || position === 'bottom';
        var ticksPosition = position === 'top' || position === 'left' ? 'before' : 'after';
        return React.createElement(Axis$1, _extends$2({
          key: position
        }, axis, {
          axis: isXAxis ? 'x' : 'y',
          x: position === 'right' ? width : 0,
          y: position === 'bottom' ? height : 0,
          scale: isXAxis ? xScale : yScale,
          length: isXAxis ? width : height,
          ticksPosition: ticksPosition,
          theme: theme,
          animate: animate,
          motionDamping: motionDamping,
          motionStiffness: motionStiffness
        }));
      }));
    }
  }]);
  return Axes;
}(PureComponent);
_defineProperty$3(Axes, "propTypes", _objectSpread$2({
  xScale: PropTypes.func.isRequired,
  yScale: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  top: axisPropType,
  right: axisPropType,
  bottom: axisPropType,
  left: axisPropType,
  theme: PropTypes.shape({
    axis: axisThemePropType.isRequired
  }).isRequired
}, motionPropTypes));

var degreesToRadians = function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
};

function _objectSpread$3(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty$4(target, key, source[key]); }); } return target; }
function _defineProperty$4(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var renderAxisToCanvas = function renderAxisToCanvas(ctx, _ref) {
  var axis = _ref.axis,
      scale = _ref.scale,
      _ref$x = _ref.x,
      x = _ref$x === void 0 ? 0 : _ref$x,
      _ref$y = _ref.y,
      y = _ref$y === void 0 ? 0 : _ref$y,
      length = _ref.length,
      ticksPosition = _ref.ticksPosition,
      tickValues = _ref.tickValues,
      _ref$tickSize = _ref.tickSize,
      tickSize = _ref$tickSize === void 0 ? 5 : _ref$tickSize,
      _ref$tickPadding = _ref.tickPadding,
      tickPadding = _ref$tickPadding === void 0 ? 5 : _ref$tickPadding,
      _ref$tickRotation = _ref.tickRotation,
      tickRotation = _ref$tickRotation === void 0 ? 0 : _ref$tickRotation,
      format = _ref.format,
      theme = _ref.theme;
  var _computeCartesianTick = computeCartesianTicks({
    axis: axis,
    scale: scale,
    ticksPosition: ticksPosition,
    tickValues: tickValues,
    tickSize: tickSize,
    tickPadding: tickPadding,
    tickRotation: tickRotation,
    engine: 'canvas'
  }),
      ticks = _computeCartesianTick.ticks,
      textAlign = _computeCartesianTick.textAlign,
      textBaseline = _computeCartesianTick.textBaseline;
  ctx.save();
  ctx.translate(x, y);
  ctx.textAlign = textAlign;
  ctx.textBaseline = textBaseline;
  ctx.font = "".concat(theme.axis.ticks.text.fontSize, "px ").concat(theme.axis.ticks.text.fontFamily);
  ctx.lineWidth = theme.axis.domain.line.strokeWidth;
  ctx.lineCap = 'square';
  ctx.strokeStyle = theme.axis.domain.line.stroke;
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(axis === 'x' ? length : 0, axis === 'x' ? 0 : length);
  ctx.stroke();
  ticks.forEach(function (tick) {
    ctx.lineWidth = theme.axis.ticks.line.strokeWidth;
    ctx.lineCap = 'square';
    ctx.strokeStyle = theme.axis.ticks.line.stroke;
    ctx.beginPath();
    ctx.moveTo(tick.x, tick.y);
    ctx.lineTo(tick.x + tick.lineX, tick.y + tick.lineY);
    ctx.stroke();
    var value = format !== undefined ? format(tick.value) : tick.value;
    ctx.save();
    ctx.translate(tick.x + tick.textX, tick.y + tick.textY);
    ctx.rotate(degreesToRadians(tickRotation));
    ctx.fillStyle = theme.axis.ticks.text.fill;
    ctx.fillText(value, 0, 0);
    ctx.restore();
  });
  ctx.restore();
};
var positions$1 = ['top', 'right', 'bottom', 'left'];
var renderAxesToCanvas = function renderAxesToCanvas(ctx, _ref2) {
  var xScale = _ref2.xScale,
      yScale = _ref2.yScale,
      width = _ref2.width,
      height = _ref2.height,
      top = _ref2.top,
      right = _ref2.right,
      bottom = _ref2.bottom,
      left = _ref2.left,
      theme = _ref2.theme;
  var axes = {
    top: top,
    right: right,
    bottom: bottom,
    left: left
  };
  positions$1.forEach(function (position) {
    var axis = axes[position];
    if (!axis) return null;
    var isXAxis = position === 'top' || position === 'bottom';
    var ticksPosition = position === 'top' || position === 'left' ? 'before' : 'after';
    var scale = isXAxis ? xScale : yScale;
    var format = getFormatter(axis.format, scale);
    renderAxisToCanvas(ctx, _objectSpread$3({}, axis, {
      axis: isXAxis ? 'x' : 'y',
      x: position === 'right' ? width : 0,
      y: position === 'bottom' ? height : 0,
      scale: scale,
      format: format,
      length: isXAxis ? width : height,
      ticksPosition: ticksPosition,
      theme: theme
    }));
  });
};

export { Axes, Axis$1 as Axis, axisPropType, axisPropTypes, renderAxesToCanvas, renderAxisToCanvas };
