import React, { useEffect, useState } from "react";
import { FcSynchronize } from "react-icons/fc";
import {
  Button,
  Card,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import GenericStat from "./GenericStat";
import { calculateWPMFromPromptData } from "../utils/calculations";
import { PromptData } from "TypingMain";
import { Kbd } from "@chakra-ui/react";
import { calculateTotalSeconds } from "../utils/calculations";
interface ITypingDataAndControlsProps {
  seconds: number;
  reset: () => void;
  promptData: PromptData;
  expectedWPM: number;
}

const TypingDataAndControls = ({
  promptData,
  seconds,
  reset,
  expectedWPM,
}: ITypingDataAndControlsProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Card
      variant={"elevated"}
      rounded="3xl"
      shadow={"2xl"}
      p={6}
      width="min(80vw, 700px)"
      className={useColorMode().colorMode === "dark" ? "colour-dark" : "colour"}
    >
      <div className="row">
        <GenericStat label="Expected WPM" number={expectedWPM.toString()} />
        <GenericStat
          label="Actual WPM"
          number={calculateWPMFromPromptData(promptData, true).toString()}
        />
        <div className="right-panel">
          <GenericStat
            label="Time"
            number={calculateTotalSeconds(promptData, true).toString()}
          />
          <Button
            variant="solid"
            bg={useColorModeValue("white", "gray.800")}
            onClick={reset}
            className="reset-button"
            rightIcon={
              <>
                <Kbd>Alt</Kbd> + <Kbd>R</Kbd>
              </>
            }
          >
            Reset
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default TypingDataAndControls;
