type SystemColors = {
  backgroundPrimary: string;
  fontColorPrimary: string;
  fontColorSecondary: string;
  fontColorDanger: string;
  fontColorGray: string;
  shadowColorBottom: string;
  shadowColorTop: string;
  blinkingBackground: string;
};

export const colors: { light: SystemColors; dark: SystemColors } = {
  light: {
    backgroundPrimary: "#ffffff",
    fontColorPrimary: "#000000",
    fontColorSecondary: "blue",
    fontColorDanger: "red",
    fontColorGray: "gray",
    shadowColorBottom: "#d6d9d9",
    shadowColorTop: "#d6d9d9",
    blinkingBackground: "black",
  },
  dark: {
    backgroundPrimary: "#000000",
    fontColorPrimary: "#ffffff",
    fontColorSecondary: "#99C1F1",
    fontColorDanger: "#F77152",
    fontColorGray: "#B4A1A1",
    shadowColorBottom: "#242424",
    shadowColorTop: "#242424",
    blinkingBackground: "#00b46b",
  },
};

export const setColors = (colorScheme: SystemColors) => {
  const root = document.querySelector(":root") as HTMLElement;
  if (!root) return;
  root.style.setProperty("--backgroundPrimary", colorScheme.backgroundPrimary);
  root.style.setProperty("--fontColorPrimary", colorScheme.fontColorPrimary);
  root.style.setProperty(
    "--fontColorSecondary",
    colorScheme.fontColorSecondary
  );
  root.style.setProperty("--fontColorDanger", colorScheme.fontColorDanger);
  root.style.setProperty("--fontColorGray", colorScheme.fontColorGray);
  root.style.setProperty("--shadowColorBottom", colorScheme.shadowColorBottom);
  root.style.setProperty("--shadowColorTop", colorScheme.shadowColorTop);
  root.style.setProperty(
    "--blinkingBackground",
    colorScheme.blinkingBackground
  );
};
