import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  Badge,
  useColorModeValue,
  Card,
  Spinner,
  Input,
  Flex,
  Container,
} from "@chakra-ui/react";
import React, { useState, useEffect, useRef } from "react";

import { useUser } from "@supabase/auth-helpers-react";
import { Session, User } from "@supabase/supabase-js";
import Header from "./Header";

import { supabase } from "../utils/supabaseClient";
import type { History } from "TypingMain";
import { useColorMode } from "@chakra-ui/react";
import { setColors, colors } from "../utils/color";
import { CalendarDatum, ResponsiveCalendar } from "@nivo/calendar";
import { Tab, TabPanel, TabPanels, Tabs, TabList } from "@chakra-ui/react";
import GenericStat from "./GenericStat";
import { CiEdit } from "react-icons/ci";
import { profile } from "console";

interface ProfileProps {
  session: Session | null;
  history: History;
  isAdmin: boolean | null;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  fullName: string | null;
  avatarUrl: string | null;
  user: User | null;
  setAvatarUrl: React.Dispatch<React.SetStateAction<string | null>>;
  username: string | null;
  setUsername: React.Dispatch<React.SetStateAction<string | null>>;
}

type TimeFrame = "today" | "past week" | "past month" | "all time";

const Profile = ({
  session,
  history,
  isAdmin,
  setFullName,
  fullName,
  avatarUrl,
  user,
  setAvatarUrl,
  username,
  setUsername,
}: ProfileProps) => {
  const { colorMode } = useColorMode();

  console.log("sessions", JSON.stringify(history, null, 2));

  const uploadAvatar = async (file: File) => {
    try {
      const { data, error } = await supabase.storage
        .from("avatars")
        .upload(`${session?.user.id}${Math.random().toString()}`, file, {
          cacheControl: "3600",
          upsert: false,
        });

      if (error) {
        throw error;
      }

      if (data) {
        const fileUrl = data["path"];
        const publicUrl = await supabase.storage
          .from("avatars")
          .getPublicUrl(fileUrl);

        const response = await supabase
          .from("profiles")
          .update({ avatar_url: publicUrl.data.publicUrl })
          .eq("id", session?.user.id);

        if (response.error) {
          throw response.error;
        }

        setAvatarUrl(publicUrl.data.publicUrl);
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const getRelevantHistory = (
    history: History,
    timeFrame: TimeFrame
  ): History => {
    let relevantHistory: History = [];

    switch (timeFrame) {
      case "today":
        relevantHistory = history.filter(
          (promptData) =>
            promptData.startTime.toDateString() === new Date().toDateString()
        );
        break;
      case "past week":
        relevantHistory = history.filter(
          (promptData) =>
            promptData.startTime.getTime() >=
            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        break;
      case "past month":
        relevantHistory = history.filter(
          (promptData) =>
            promptData.startTime.getTime() >=
            new Date().getTime() - 30 * 24 * 60 * 60 * 1000
        );
        break;
      case "all time":
        relevantHistory = history;
        break;
    }

    return relevantHistory;
  };

  const calculateFastestWPM = (
    history: History,
    timeFrame: TimeFrame
  ): number => {
    let relevantHistory: History = getRelevantHistory(history, timeFrame);

    let fastestWPM = 0;
    relevantHistory.forEach((promptData) => {
      if (promptData.wpm && promptData.wpm > fastestWPM) {
        fastestWPM = promptData.wpm;
      }
    });
    return fastestWPM;
  };

  const calculateAverageWPM = (
    history: History,
    timeFrame: TimeFrame
  ): number => {
    let relevantHistory: History = getRelevantHistory(history, timeFrame);

    let totalWPM = 0;
    let totalSessions = 0;
    relevantHistory.forEach((session) => {
      if (session.wpm) {
        totalWPM += session.wpm;
        totalSessions++;
      }
    });
    return totalWPM / totalSessions;
  };

  const calculateMinutesTyped = (
    history: History,
    timeFrame: "today" | "past week" | "past month" | "all time"
  ): number => {
    if (history.length === 0) return 0;
    let relevantHistory: History = getRelevantHistory(history, timeFrame);
    let totalSeconds = 0;
    relevantHistory.forEach((promptData) => {
      totalSeconds += promptData.seconds;
    });
    // round to 1 decimal place
    return Math.round((totalSeconds / 60) * 10) / 10;
  };

  const calculateTotalSessions = (
    history: History,
    timeFrame: "today" | "past week" | "past month" | "all time"
  ): number => {
    let relevantHistory: History = getRelevantHistory(history, timeFrame);
    return relevantHistory.length;
  };

  if (colorMode === "dark") {
    setColors(colors.dark);
  } else {
    setColors(colors.light);
  }

  const convertDateToCalendarFormat = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const earliestDateInHistory = (history: History): Date => {
    if (history.length === 0) return new Date();
    return history[0].startTime;
  };

  const findJanFirstOfEarliestYear = (history: History): Date => {
    let earliestDate = earliestDateInHistory(history);
    return new Date(earliestDate.getFullYear(), 0, 1);
  };

  const latestDateInHistory = (history: History): Date => {
    if (history.length === 0) return new Date();
    return history[history.length - 1].startTime;
  };

  const calculateCalendarData = (history: History): CalendarDatum[] => {
    let calendarData: CalendarDatum[] = [];
    let earliestDate = earliestDateInHistory(history);
    let latestDate = latestDateInHistory(history);
    let currentDate = new Date(earliestDate);
    while (currentDate <= latestDate) {
      let sessionCount = 0;
      history.forEach((session) => {
        if (session.startTime.toDateString() === currentDate.toDateString()) {
          sessionCount++;
        }
      });
      if (sessionCount > 0) {
        calendarData.push({
          day: convertDateToCalendarFormat(currentDate),
          value: sessionCount,
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    console.log(calendarData);
    return calendarData;
  };

  console.log("calendardata", calculateCalendarData(history));

  const inputFile = useRef<HTMLInputElement | null>(null);
  async function chooseAvatarFileAndUpdate() {
    inputFile.current?.click();
  }

  async function handleAvatarUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const file = event.target.files?.[0];
    if (file) {
      await uploadAvatar(file);
    }
  }

  return (
    <div>
      <div>
        <Center mb={"3rem"}>
          <Box
            width="20rem"
            boxShadow={"2xl"}
            rounded={"3xl"}
            p={6}
            textAlign={"center"}
            className={colorMode === "dark" ? "colour-dark" : "colour"}
          >
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleAvatarUpload}
            />
            <Avatar
              size={"xl"}
              src={avatarUrl || undefined}
              mb={4}
              pos={"relative"}
              onClick={chooseAvatarFileAndUpdate}
              title="Click to change avatar"
              cursor={"pointer"}
            />
            <Card
              bg={useColorModeValue("white", "gray.900")}
              boxShadow={"2xl"}
              rounded={"3xl"}
              p={6}
              textAlign={"center"}
              alignItems="center"
              alignContent={"center"}
            >
              <Heading fontSize={"2xl"} fontFamily={"body"}>
                <Flex alignItems={"center"} justifyContent="center">
                  <div>{username} </div>
                </Flex>
              </Heading>
              <Text fontWeight={600} color={"gray.500"} mb={4}>
                {user?.email}
              </Text>

              {isAdmin ? (
                <Badge colorScheme="green">Admin</Badge>
              ) : (
                <Badge colorScheme="blue">Verified user</Badge>
              )}
            </Card>
          </Box>
        </Center>
        <Center>
          <Card
            bg={useColorModeValue("white", "gray.900")}
            boxShadow={"2xl"}
            rounded={"3xl"}
            p={6}
            textAlign={"center"}
            alignItems="center"
            alignContent={"center"}
            width="min(80vw, 900px)"
          >
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab>Today</Tab>
                <Tab>Past Week</Tab>
                <Tab>Past Month</Tab>
                <Tab>All time</Tab>
              </TabList>

              <TabPanels className="profile-stats-panels">
                <TabPanel className="timely-profile-stats">
                  <GenericStat
                    label="Average WPM (Today)"
                    number={calculateAverageWPM(history, "today").toFixed(2)}
                  />

                  <GenericStat
                    label="Fastest WPM (Today)"
                    number={calculateFastestWPM(history, "today").toFixed(2)}
                  />

                  <GenericStat
                    label="Time Spent Typing in minutes (Today)"
                    number={calculateMinutesTyped(history, "today").toFixed(2)}
                  />

                  <GenericStat
                    label="Total Sessions (Today)"
                    number={calculateTotalSessions(history, "today").toFixed(0)}
                  />
                </TabPanel>
                <TabPanel className="timely-profile-stats">
                  <GenericStat
                    label="Average WPM (Past Week)"
                    number={calculateAverageWPM(history, "past week").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Fastest WPM (Past Week)"
                    number={calculateFastestWPM(history, "past week").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Time Spent Typing in minutes (Past Week)"
                    number={calculateMinutesTyped(history, "past week").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Total Sessions (Past Week)"
                    number={calculateTotalSessions(
                      history,
                      "past week"
                    ).toFixed(0)}
                  />
                </TabPanel>
                <TabPanel className="timely-profile-stats">
                  <GenericStat
                    label="Average WPM (Past Month)"
                    number={calculateAverageWPM(history, "past month").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Fastest WPM (Past Month)"
                    number={calculateFastestWPM(history, "past month").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Time Spent Typing in minutes (Past Month)"
                    number={calculateMinutesTyped(
                      history,
                      "past month"
                    ).toFixed(2)}
                  />

                  <GenericStat
                    label="Total Sessions (Past Month)"
                    number={calculateTotalSessions(
                      history,
                      "past month"
                    ).toFixed(0)}
                  />
                </TabPanel>
                <TabPanel className="timely-profile-stats">
                  <GenericStat
                    label="Average WPM"
                    number={calculateAverageWPM(history, "all time").toFixed(2)}
                  />

                  <GenericStat
                    label="Fastest WPM"
                    number={calculateFastestWPM(history, "all time").toFixed(2)}
                  />

                  <GenericStat
                    label="Time Spent Typing in minutes"
                    number={calculateMinutesTyped(history, "all time").toFixed(
                      2
                    )}
                  />

                  <GenericStat
                    label="Total Sessions"
                    number={calculateTotalSessions(history, "all time").toFixed(
                      0
                    )}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </Center>
        <Center>
          <Card
            bg={useColorModeValue("white", "gray.900")}
            boxShadow={"2xl"}
            rounded={"3xl"}
            p={6}
            textAlign={"center"}
            alignItems="center"
            alignContent={"center"}
            width="min(80vw, 900px)"
            height={"13rem"}
            mt={6}
          >
            <ResponsiveCalendar
              data={calculateCalendarData(history)}
              from={convertDateToCalendarFormat(
                findJanFirstOfEarliestYear(history)
              )}
              to={convertDateToCalendarFormat(latestDateInHistory(history))}
              emptyColor={useColorModeValue("#eee", "#333")}
              colors={[
                "#c1e5d3",
                "#a2d8bd",
                "#83cba7",
                "#64be91",
                "#45b17b",
                "#27a466",
                "#089750",
              ]}
              yearSpacing={39}
              monthBorderColor={useColorModeValue("#ffffff", "#171923")}
              dayBorderWidth={1}
              dayBorderColor={useColorModeValue("#ffffff", "#171923")}
              maxValue={undefined}
              minValue={0}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 35,
                  itemCount: 3,
                  itemWidth: 41,
                  itemHeight: 35,
                  itemsSpacing: 13,
                  itemDirection: "right-to-left",
                },
              ]}
            />
          </Card>
        </Center>
        <Container mb={10}></Container>
      </div>
    </div>
  );
};

export default Profile;
