import React, { useEffect } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Container,
  Center,
  TableCaption,
  Avatar,
  Flex,
  Text,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../utils/supabaseClient";
import { Database } from "utils/database.types";

interface LeaderboardProps {
  session: Session | null;
}

const Leaderboard = ({ session }: LeaderboardProps) => {
  const [loading, setLoading] = React.useState(true);
  const [topTypingHistories24, setTopTypingHistories24] =
    React.useState<TopTypingHistoriesResponse24>();
  const [topTypingHistoriesWeek, setTopTypingHistoriesWeek] =
    React.useState<TopTypingHistoriesResponseWeek>();
  const [topTypingHistoriesMonth, setTopTypingHistoriesMonth] =
    React.useState<TopTypingHistoriesResponseMonth>();
  const [topTypingHistoriesAllTime, setTopTypingHistoriesAllTime] =
    React.useState<TopTypingHistoriesResponseAllTime>();

  const navigate = useNavigate();

  async function fetchTopTypingHistories24() {
    const { data, error } = await supabase
      .from("top_10_specific_typing_histories")
      .select("*");

    if (error) {
      console.error("Error fetching leaderboard data:", error);
    } else {
      console.log("Leaderboard data:", data);
    }
    return data;
  }
  type TopTypingHistoriesResponse24 = Awaited<
    ReturnType<typeof fetchTopTypingHistories24>
  >;

  async function fetchTopTypingHistoriesWeek() {
    const { data, error } = await supabase
      .from("top_10_specific_typing_histories_past_week")
      .select("*");

    if (error) {
      console.error("Error fetching leaderboard data:", error);
    } else {
      console.log("Leaderboard data:", data);
    }
    return data;
  }

  type TopTypingHistoriesResponseWeek = Awaited<
    ReturnType<typeof fetchTopTypingHistoriesWeek>
  >;

  async function fetchTopTypingHistoriesMonth() {
    const { data, error } = await supabase

      .from("top_10_specific_typing_histories_past_month")
      .select("*");

    if (error) {
      console.error("Error fetching leaderboard data:", error);
    } else {
      console.log("Leaderboard data:", data);
    }
    return data;
  }

  type TopTypingHistoriesResponseMonth = Awaited<
    ReturnType<typeof fetchTopTypingHistoriesMonth>
  >;

  async function fetchTopTypingHistoriesAllTime() {
    const { data, error } = await supabase

      .from("top_10_specific_typing_histories_all_time")
      .select("*");

    if (error) {
      console.error("Error fetching leaderboard data:", error);
    } else {
      console.log("Leaderboard data:", data);
    }

    return data;
  }

  type TopTypingHistoriesResponseAllTime = Awaited<
    ReturnType<typeof fetchTopTypingHistoriesAllTime>
  >;

  const renderTable = (
    topTypingHistories: TopTypingHistoriesResponse24 | undefined,
    caption: string
  ) => (
    <Table variant="striped">
      <TableCaption>{caption}</TableCaption>
      <Thead>
        <Tr>
          <Th>Rank</Th>
          <Th>User</Th>
          <Th isNumeric>WPM</Th>
        </Tr>
      </Thead>
      <Tbody>
        {topTypingHistories?.map((typingHistory, index) => (
          <Tr key={typingHistory.id}>
            <Td>{index + 1}</Td>
            <Td>
              <Flex
                gap={2}
                alignItems={"center"}
                cursor={"pointer"}
                onClick={() => {
                  navigate(`/profile/${typingHistory.username}`);
                }}
              >
                <Avatar size="sm" src={typingHistory.avatar_url as string} />
                <Text>{typingHistory.username}</Text>
              </Flex>
            </Td>
            <Td isNumeric>{typingHistory.wpm}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  useEffect(() => {
    async function getTopTypingHistories() {
      const topTypingHistories24 = await fetchTopTypingHistories24();
      const topTypingHistoriesWeek = await fetchTopTypingHistoriesWeek();
      const topTypingHistoriesMonth = await fetchTopTypingHistoriesMonth();
      const topTypingHistoriesAllTime = await fetchTopTypingHistoriesAllTime();

      setTopTypingHistories24(topTypingHistories24);
      setTopTypingHistoriesWeek(topTypingHistoriesWeek);
      setTopTypingHistoriesMonth(topTypingHistoriesMonth);
      setTopTypingHistoriesAllTime(topTypingHistoriesAllTime);

      setLoading(false);
    }

    getTopTypingHistories();
  }, []);

  return (
    <Center>
      <Container
        minW={"min(80vw, 700px)"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={6}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Tabs
            isLazy
            variant="soft-rounded"
            colorScheme="green"
            minW="min(80vw, 700px)"
          >
            <TabList
              alignItems={"center"}
              alignContent={"center"}
              justifyContent={"center"}
            >
              <Tab>Today</Tab>
              <Tab>This Week</Tab>
              <Tab>This Month</Tab>
              <Tab>All Time</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {renderTable(
                  topTypingHistories24,
                  "Top 10 WPMs of the past 24 hours (max 1 entry per user)"
                )}
              </TabPanel>
              <TabPanel>
                {renderTable(
                  topTypingHistoriesWeek,
                  "Top 10 WPMs of the past week (max 1 entry per user)"
                )}
              </TabPanel>
              <TabPanel>
                {renderTable(
                  topTypingHistoriesMonth,
                  "Top 10 WPMs of the past month (max 1 entry per user)"
                )}
              </TabPanel>
              <TabPanel>
                {renderTable(
                  topTypingHistoriesAllTime,
                  "Top 10 WPMs of all time (max 1 entry per user)"
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Container>
    </Center>
  );
};

export default Leaderboard;
