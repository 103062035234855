import {
  Button,
  Card,
  Container,
  Flex,
  Kbd,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FcNext } from "react-icons/fc";
import { KeyPressRow, PromptData, Quote, type UISettings } from "../TypingMain";
import { Line } from "@nivo/line";
import { useColorMode } from "@chakra-ui/react";
import GenericStat from "./GenericStat";
import { TypingCard } from "./TypingInterface";
import {
  calculateLineGraphData,
  calculateTotalSeconds,
} from "../utils/calculations";
import { useParams } from "react-router-dom";

interface HistoryIdxProps {
  history: PromptData[];
}

// import edit icon from react-icons

interface StatsProps {
  expectedWPM: number;
  promptData: PromptData;
  setUiSettings: (uiSettings: UISettings) => void;
  setPromptData: React.Dispatch<React.SetStateAction<PromptData>>;
  setSeconds: React.Dispatch<React.SetStateAction<number>>;
  getRandomQuote: () => Promise<Quote>;
  completed: React.MutableRefObject<boolean>;
}

const HistoryIdx = ({ history }: HistoryIdxProps) => {
  const { id } = useParams();
  if (!id) return <div>Not Found</div>;
  let idINT = parseInt(id);

  const promptData = history.find((data) => data.id === idINT);
  if (!promptData) return <div>Not Found</div>;

  return (
    <div>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection={"column"}
      >
        <Card
          variant={"elevated"}
          rounded="3xl"
          shadow={"2xl"}
          p={6}
          width="min(80vw, 700px)"
          className={
            useColorMode().colorMode === "dark" ? "colour-dark" : "colour"
          }
        >
          <div className="row">
            <GenericStat
              label="Actual WPM"
              number={promptData.wpm?.toFixed(2) || ""}
            />
            <div className="right-panel">
              <GenericStat
                label="Time"
                number={calculateTotalSeconds(promptData, false).toString()}
              />
            </div>
          </div>
        </Card>
        <Tabs
          mt="10"
          variant="soft-rounded"
          colorScheme="green"
          width={"min(80vw, 700px)"}
          align="center"
        >
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Detailed Stats</Tab>
            <Tab>Key Presses</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <div className="line-container">
                  <Line
                    data={[
                      { id: "wpm", data: calculateLineGraphData(promptData) },
                    ]}
                    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Time (Seconds)",
                      legendOffset: 36,
                      legendPosition: "middle",
                    }}
                    axisLeft={{
                      tickSize: 5,
                      tickPadding: 5,
                      tickRotation: 0,
                      legend: "Words Per Minute (WPM)",
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    colors={"#00bfff"}
                    // colors={{ scheme: "category10" }}
                    width={600}
                    height={400}
                    theme={{
                      axis: {
                        ticks: {
                          line: {
                            stroke: useColorModeValue("black", "white"),
                          },
                          text: {
                            fill: useColorModeValue("black", "white"),
                          },
                        },
                        legend: {
                          text: {
                            fill: useColorModeValue("black", "white"),
                          },
                        },
                      },
                      tooltip: {
                        container: {
                          background: useColorModeValue("white", "gray.800"),
                        },
                      },
                    }}
                  />
                </div>
                <TypingCard
                  promptData={promptData}
                  focusInput={() => {}}
                  statsMode={true}
                />
              </Container>
            </TabPanel>
            <TabPanel>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Tooltip label="Uncorrected Error Rate %">
                        <Th>Error Rate</Th>
                      </Tooltip>
                      <Tooltip label="Average Inter-key Interval">
                        <Th>AVG IKI</Th>
                      </Tooltip>
                      <Tooltip label="Error Corrections Per Character">
                        <Th>ECPC</Th>
                      </Tooltip>
                      <Tooltip label="Keystrokes Per Character">
                        <Th>KSPC</Th>
                      </Tooltip>
                      <Tooltip label="Rollover Ratio">
                        <Th>ROR</Th>
                      </Tooltip>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{promptData.errorRate?.toFixed(2)}%</Td>
                      <Td>{promptData.avgIki?.toFixed(2)}</Td>
                      <Td>{promptData.ecpc?.toFixed(2)}</Td>
                      <Td>{promptData.kspc?.toFixed(2)}</Td>
                      <Td>{promptData.ror?.toFixed(2)}</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>

            <TabPanel>
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>KeyCode</Th>
                      <Th>Letter</Th>
                      <Th>Press Time</Th>
                      <Th>Release Time</Th>
                      <Th>Duration</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {promptData.keyPresses.map((keyPress: KeyPressRow) => {
                      return (
                        <Tr>
                          <Td>{keyPress.keyCode}</Td>
                          <Td>{keyPress.letter}</Td>
                          <Td>{keyPress.pressTime}</Td>
                          <Td>{keyPress.releaseTime}</Td>
                          <Td>
                            {keyPress.releaseTime
                              ? keyPress.releaseTime - keyPress.pressTime
                              : "N/A"}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </div>
  );
};

export default HistoryIdx;
