import React from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Center,
  Alert,
  AlertDescription,
  AlertTitle,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";

import { supabase } from "../utils/supabaseClient";
import { FcGoogle } from "react-icons/fc";
import { Link as RouterLink, useNavigate } from "react-router-dom";

interface ILoginProps {
  setEmail: (email: string) => void;
  email: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  handleLogin: (e: any) => Promise<void>;
  password: string;
  setPassword: (password: string) => void;
  logInWithGoogle: () => Promise<void>;
}

const LoginForm = ({
  setEmail,
  email,
  password,
  setPassword,
  loading,
  setLoading,
  handleLogin,
  logInWithGoogle,
}: ILoginProps) => {
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <h1 className="title">VORAK</h1>
          <Heading fontSize={"4xl"}>Log in to your account</Heading>
          <Text fontSize={"lg"} color={"gray.600"}>
            to continue typing like a pro ✌️
          </Text>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>

            <Stack spacing={10}>
              <Stack
                direction={{ base: "column", sm: "row" }}
                align={"start"}
                justify={"space-between"}
              >
                <Checkbox>Remember me</Checkbox>
                <Link color={"blue.400"}>Forgot password?</Link>
              </Stack>
              <Button
                bg={"blue.400"}
                color={"white"}
                onClick={handleLogin}
                _hover={{
                  bg: "blue.500",
                }}
              >
                Login
              </Button>

              <Button
                onClick={logInWithGoogle}
                w={"full"}
                variant={"outline"}
                leftIcon={<FcGoogle />}
              >
                <Center>
                  <Text>Login with Google</Text>
                </Center>
              </Button>
              <Link as={RouterLink} color={"blue.400"} to="/signup">
                Don't have an account?
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

const Login = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const navigate = useNavigate();

  const logInWithGoogle = async () => {
    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin,
        },
      });
      if (error) throw error;
    } catch (error) {
      setAlertMessage(error.error_description || error.message);
      // remove the alert after 5 seconds
      setTimeout(() => {
        setAlertMessage("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
    } catch (error) {
      setAlertMessage(error.error_description || error.message);
      // get rid of the alert after 5 seconds
      setTimeout(() => {
        setAlertMessage("");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {alertMessage && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Login failed</AlertTitle>
          <AlertDescription>{alertMessage}</AlertDescription>
        </Alert>
      )}
      <LoginForm
        setEmail={setEmail}
        email={email}
        loading={loading}
        setLoading={setLoading}
        handleLogin={handleLogin}
        password={password}
        setPassword={setPassword}
        logInWithGoogle={logInWithGoogle}
      />
    </>
  );
};

export default Login;
