import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  useColorModeValue,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
  Spacer,
} from "@chakra-ui/react";
import { FiHome, FiChevronDown, FiSun, FiMoon } from "react-icons/fi";
import { IconType } from "react-icons";
import { User } from "@supabase/supabase-js";
import { supabase } from "../utils/supabaseClient";
import { Outlet, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { AiOutlineHistory } from "react-icons/ai";
import { RiVipCrownLine } from "react-icons/ri";
interface LinkItemProps {
  name: string;
  icon: ReactNode;
  route?: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: <FiHome />, route: "/" },
  { name: "Profile", icon: <CgProfile />, route: "/profile" },
  { name: "History", icon: <AiOutlineHistory />, route: "/history" },
  { name: "Leaderboard", icon: <RiVipCrownLine />, route: "/leaderboard" },

  // { name: "Trending", icon: FiTrendingUp, route: "/trending" },
  // { name: "Quotes", icon: RiDoubleQuotesL, route: "/quotes" },
  // { name: "Favourites", icon: FiStar, route: "/favourites" },
  // { name: "Settings", icon: FiSettings, route: "/settings" },
];

const shouldHighlight = (route: string) => {
  const currentRoute = window.location.pathname;
  return currentRoute === route;
};

const chooseNavColor = (route: string, colorMode: string) => {
  if (shouldHighlight(route)) {
    return colorMode === "dark" ? "blue.500" : "blue.500";
  } else {
    return colorMode === "dark" ? "gray.500" : "gray.500";
  }
};

const NavBar = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  return (
    <Flex as="nav" align="center" justify="center" w="100%">
      {LinkItems.map((item) => (
        <Box
          key={item.name}
          as="a"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p={2}
          rounded="md"
          _hover={{
            cursor: "pointer",
            textDecoration: "none",
            bg: useColorModeValue("gray.200", "gray.700"),
          }}
          color={chooseNavColor(item.route || "", colorMode)}
          onClick={() => {
            if (item.route) {
              navigate(item.route);
            }
          }}
        >
          {item.icon}
          <Text ml={2}>{item.name}</Text>
        </Box>
      ))}
    </Flex>
  );
};

interface HeaderProps {
  avatarUrl: string | null;
}

const SidebarWithHeader = ({ avatarUrl }: HeaderProps) => {
  return (
    <Box>
      <MobileNav avatarUrl={avatarUrl} />
      <NavBar />
      <Box pt="8">
        <Outlet />
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;

interface MobileNavProps {
  avatarUrl: string | null;
}

const MobileNav = ({ avatarUrl }: MobileNavProps) => {
  const [user, setUser] = useState<User | null>(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      const userResponse = await supabase.auth.getUser();
      if (userResponse && !userResponse.error) {
        setUser(userResponse.data.user);
      }
    };

    // get username from profile

    getUser();
  }, []);
  return (
    <Flex height="20" borderBottomWidth="1px" justifyContent={"space-between"}>
      <Text className="title" ml={5}>
        VORAK
      </Text>
      <Spacer />

      <HStack mr={3}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          onClick={toggleColorMode}
          icon={colorMode === "dark" ? <FiSun /> : <FiMoon />}
        />
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} src={avatarUrl || undefined} />
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem
                onClick={() => {
                  navigate("/account");
                }}
              >
                Account
              </MenuItem>
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  } catch (error) {
    alert(error.message);
  }

  window.location.href = "https://vorak.dev";
}
