import type { KeyPressRow, PromptData } from "../TypingMain";

export function calculateWPMFromPromptData(
  promptData: PromptData,
  isDuringPrompt: boolean
): number {
  if (promptData.keyPresses.length === 0) return 0;

  const timeElapsed = calculateTotalSeconds(promptData, false);
  if (timeElapsed === 0) return 0;

  const userTyped = promptData.userInput;
  const prompt = promptData.prompt.quote;
  const correct = userTyped.filter((char, index) => char === prompt[index]);

  if (correct.length === 0) return 0;

  const wpm = (correct.length / 5 / timeElapsed) * 60;

  return +wpm.toFixed(2);
}

function getElapsedTime(promptData: PromptData): number {
  if (promptData.keyPresses.length === 0) return 0;
  const startTime = promptData.keyPresses[0].pressTime;
  const endTime =
    promptData.keyPresses[promptData.keyPresses.length - 1].pressTime;
  const timeElapsed = endTime - startTime;
  return timeElapsed;
}

function getCorrectKeyPresses(promptData: PromptData): KeyPressRow[] {
  const keyPresses = promptData.keyPresses;
  const userTyped = promptData.userInput;
  const prompt = promptData.prompt.quote;

  let correctKeyPresses: KeyPressRow[] = [];
  let keyPressIndex = 0;

  for (let i = 0; i < userTyped.length && i < prompt.length; i++) {
    while (
      keyPressIndex < keyPresses.length &&
      keyPresses[keyPressIndex].letter !== userTyped[i]
    ) {
      keyPressIndex++;
    }

    if (keyPressIndex < keyPresses.length && userTyped[i] === prompt[i]) {
      correctKeyPresses.push(keyPresses[keyPressIndex]);
    }

    keyPressIndex++;
  }

  return correctKeyPresses;
}

type LineGraphData = {
  x: number; // time
  y: number; // wpm
};

export function calculateLineGraphData(
  promptData: PromptData
): LineGraphData[] {
  const lineGraphData: LineGraphData[] = [];
  const TIME_DIVISIONS = 20;
  const timeElapsed = getElapsedTime(promptData);
  const TIME_INTERVAL = timeElapsed / TIME_DIVISIONS;
  const correctKeyPresses = getCorrectKeyPresses(promptData);

  let keyPressIndex = 0;

  // Find the start time (the earliest pressTime in correctKeyPresses)
  const startTime = correctKeyPresses.reduce((min, keyPress) => {
    return keyPress.pressTime < min ? keyPress.pressTime : min;
  }, Infinity);

  lineGraphData.push({ x: 0, y: 0 });

  for (let i = 0; i < TIME_DIVISIONS; i++) {
    const currentTime = startTime + (i + 1) * TIME_INTERVAL;
    const previousTime = startTime + i * TIME_INTERVAL;
    let intervalCharCount = 0;

    while (
      keyPressIndex < correctKeyPresses.length &&
      correctKeyPresses[keyPressIndex].pressTime <= currentTime
    ) {
      if (correctKeyPresses[keyPressIndex].pressTime > previousTime) {
        intervalCharCount++;
      }
      keyPressIndex++;
    }

    // Assuming 5 characters per word
    const wpm = intervalCharCount / 5 / (TIME_INTERVAL / 1000 / 60);
    lineGraphData.push({ x: (currentTime - startTime) / 1000, y: wpm });
  }

  // round the x values to 2 decimal places
  lineGraphData.forEach((dataPoint) => {
    dataPoint.x = +dataPoint.x.toFixed(1);
  });

  return lineGraphData;
}

// uncorrected errors as a percentage of the prompt
export function calculateErrorRate(promptData: PromptData): number {
  const userTyped = promptData.userInput;
  const prompt = promptData.prompt.quote;

  const correct = userTyped.filter((char, index) => char === prompt[index]);
  const incorrect = userTyped.filter((char, index) => char !== prompt[index]);

  if (incorrect.length === 0) return 0;

  const uncorrectedErrors = incorrect.filter(
    (char, index) => char !== prompt[index + correct.length]
  );

  return uncorrectedErrors.length / prompt.length;
}

export function calculateAVG_IKI(promptData: PromptData): number {
  const keyPresses = promptData.keyPresses;
  if (keyPresses.length <= 1) return 0;

  let totalTimeBetweenKeyPresses = 0;

  for (let i = 1; i < keyPresses.length; i++) {
    totalTimeBetweenKeyPresses +=
      keyPresses[i].pressTime - keyPresses[i - 1].pressTime;
  }

  const avgIKI = totalTimeBetweenKeyPresses / (keyPresses.length - 1);
  return avgIKI;
}

export function calculateErrorCorrectionsPerChar(
  promptData: PromptData
): number {
  const keyPresses = promptData.keyPresses;
  const BACKSPACE_KEY = "Backspace";

  if (keyPresses.length === 0) return 0;

  let backspaceCount = 0;
  let totalCharsTyped = 0;

  for (let i = 0; i < keyPresses.length; i++) {
    if (keyPresses[i].letter === BACKSPACE_KEY) {
      backspaceCount++;
    }
    totalCharsTyped++;
  }

  if (backspaceCount === 0) return 0;

  const ecpc = backspaceCount / totalCharsTyped;
  return ecpc;
}

export function calculateKeystrokesPerChar(promptData: PromptData): number {
  const keyPresses = promptData.keyPresses;
  const prompt = promptData.prompt.quote;

  if (keyPresses.length === 0 || prompt.length === 0) return 0;

  const totalCharsTyped = keyPresses.length;
  const kspc = totalCharsTyped / prompt.length;

  return kspc;
}

export function calculateRolloverRatio(promptData: PromptData): number {
  const keyPresses = promptData.keyPresses;

  if (keyPresses.length <= 1) return 0;

  let rollovers = 0;

  for (let i = 1; i < keyPresses.length; i++) {
    if (
      keyPresses[i - 1].releaseTime !== null &&
      keyPresses[i].pressTime < (keyPresses[i - 1]?.releaseTime ?? 0)
    ) {
      rollovers++;
    }
  }

  const ror = rollovers / (keyPresses.length - 1);
  return ror;
}

export function calculateTotalSeconds(
  promptData: PromptData,
  isDuringPrompt: boolean
): number {
  const keyPresses = promptData.keyPresses;

  if (keyPresses.length === 0) return 0;

  const startTime = keyPresses[0].pressTime;
  let endTime = keyPresses[keyPresses.length - 1].pressTime;
  if (isDuringPrompt) endTime = Date.now();

  const totalSeconds = (endTime - startTime) / 1000;

  if (isDuringPrompt) {
    return +totalSeconds.toFixed(0);
  } else {
    return +totalSeconds.toFixed(2);
  }
}
